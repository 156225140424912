import React, { useState, useEffect } from "react";
import { BrowserRouter, Link } from "react-router-dom";
import useReq from "../../hook/useReq";
import RegisterNow from "./RegisterNow";
import Swal from "sweetalert2";

const SignIn = ({ update, makeMeFalse }) => {
  const [authError, setAuthError] = useState("");

  const [appLoaded, setAppLoaded] = useState(false);
  const [progress, setProgress] = useState(false);
  const [startLoadProgress, setStartLoadProgress] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { error, loading, requestData, response, clear } = useReq();
  useEffect(() => {
    if (response !== null) {
      makeMeFalse(false);
      update({ ...response });
      localStorage.setItem("token", response.token);
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      const message = typeof error === "string" ? error : error.message;
      setProgress(false);
      Swal.fire({
        title: "Failed to sign in",
        text: `${message}`,
        icon: "error",
        iconColor: "red",
        showConfirmButton: true,
        confirmButtonText: "Try Again",
        confirmButtonColor: "#0a5c7b",
      });
    }
  }, [error]);

  useEffect(() => {
    if (loading) {
      setStartLoadProgress(true);
    } else if (loading === false) {
      setAppLoaded(true);
    }
  }, [loading]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = (e) => {
    e.preventDefault();
    clear();
    setAuthError("");
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setAuthError("You have entered an invalid email address!");
      return;
    }

    if (!password) {
      setAuthError("Password is required*");
      return;
    }
    if (password.length < 8) {
      setAuthError("Invalid password");
      return;
    }
    setProgress(true);

    requestData("POST", "/auth/login", { email, password });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div id="kt_body" className="app-blank">
      <div className="d-flex flex-column flex-root vh-100" id="kt_app_root">
        <div
          className="d-flex flex-lg-row-fluid bgi-size-cover flex-1 bgi-position-center order-1 order-lg-2 staticblue"
          style={{
            backgroundImage: "url(assets/media/signin/signin-cover.png)",
          }}
        >
          <div className="d-flex flex-1 justify-content-center align-items-center p-5">
            <div className="d-flex w-75 w-lg-75 p-0 bg-light rounded-3">
              <div className="col-5 d-none d-lg-flex col-md-5 col-lg-7">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <img
                    src="/assets/media/signin/signin-logo.svg"
                    className="px-5 w-100 w-md-75"
                  />
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center p-5 flex-1">
                <div className="d-block text-center d-lg-none mb-5">
                  <img
                    src="/assets/media/signin/collabworx-logo.png"
                    className="px-5 w-100 h-55px"
                  />
                </div>
                <div className="align-items-center d-flex justify-content-center p-md-5 ps-0 ps-lg-0 pt-md-0 pt-lg-5 w-100">
                  <div className="w-100 h-100 p-5 rounded-3 shadow">
                    <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center p-4 bg-light rounded-3">
                      <div className="fs-4 text-center mb-3">
                        <h1>Hello!</h1>
                      </div>
                      <div className="fs-5">
                        <h3>Sign In</h3>
                      </div>

                      <div className="mt-3 w-100">
                        <form onSubmit={handleSignIn}>
                          {authError && (
                            <p style={{ color: "red" }}>{authError}</p>
                          )}
                          {error && (
                            <p style={{ color: "red" }}>{error.message}</p>
                          )}
                          <input
                            placeholder="Email Id"
                            type="email"
                            autoFocus
                            autoComplete="off"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="bg-collabworx w-100 rounded-1 my-3 px-3 h-35px"
                          />
                          <div className="fv-row position-relative mb-3">
                            <input
                              placeholder="Password"
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              autoComplete="off"
                              className="bg-collabworx w-100 rounded-1 my-3 px-3 h-35px"
                            />
                            <span
                              className=" position-absolute translate-middle top-50 end-0 me-2 text-dark"
                              onClick={handleTogglePasswordVisibility}
                            >
                              {showPassword ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-slash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                  <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                              )}
                            </span>
                          </div>

                          <div className="d-flex text-dark focus">
                            <Link
                              to="/reset-password"
                              className="ms-auto hover-elevate-up"
                            >
                              <p>Forgot Password ?</p>
                            </Link>
                          </div>

                          <button
                            type="submit"
                            className="btn collabworx-light my-3 py-2 w-100 text-light"
                          >
                            {progress ? (
                              <div
                                className="spinner-border"
                                role="status"
                              ></div>
                            ) : (
                              <span className="indicator-label">Sign In</span>
                            )}
                          </button>
                        </form>
                        <div className="d-flex justify-content-center align-items-center text-dark">
                          <span>
                            <a
                              href="/termsofuse"
                              target="_blank"
                              className="fs-9 fs-md-8 px-2"
                            >
                              Terms of use
                            </a>
                          </span>
                          |
                          <span>
                            <a
                              href="/shipping-policy"
                              target="_blank"
                              className="fs-9 fs-md-8 px-2"
                            >
                              Shipping policy
                            </a>
                          </span>
                          |
                          <span>
                            <a
                              href="/aboutus"
                              target="_blank"
                              className="fs-9 fs-md-8 px-2"
                            >
                              About us
                            </a>
                          </span>
                        </div>
                        <div className="d-flex justify-content-center align-items-center text-dark">
                          <span>
                            <a
                              href="/refund-policy"
                              target="_blank"
                              className="fs-9 fs-md-8 px-2"
                            >
                              Cancellation and refund
                            </a>
                          </span>
                          |
                          <span>
                            <a
                              href="/contact-us"
                              target="_blank"
                              className="fs-9 fs-md-8 px-2"
                            >
                              Contact us
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
