import React from "react";
import Footer from "../Home/Footer";

function CancellationAndRefund(props) {
  return (
    <div 
    style={{ height: "100vh" }}
      className="d-flex flex-column justify-content-between"
    >
      <div className="container">
        <div className="card my-20">
          <h1 className="text-center fs-1 terms-heading text-light">
            Cancellation and refund Policy for the Collabworx
          </h1>
          <div className="card-body">

            <h2 className="terms-sub-heading">
              Refund Policy for the Collabworx
            </h2>
            <p className="fs-4">
              At AHOU Enterprises Pvt Ltd, our commitment is to deliver exceptional
              value and support through the Collabworx. Understanding the
              dynamic nature of construction projects and environmental
              assessments, we have outlined our refund policy below to address
              any concerns regarding purchases made within the app. All
              transactions made within the Collabworx, including purchases of
              services or subscriptions, are considered final. We encourage our
              users to review the details of their purchase carefully before
              completion, as we do not offer refunds or exchanges once a
              transaction is finalized. Users are responsible for understanding
              the terms of their purchases and will bear all charges resulting
              from those purchases. However, in a case where a transaction has
              been completed by you on the app or web, and money has been
              charged to your card or bank account but has not delivered within
              15 days of your completion of the transaction then you may inform
              us by sending us an email on
              <a href="mailto:vkgroup.fireapp@gmail.com">
                vkgroup.fireapp@gmail.com
              </a>
              . In such a scenario you will be entitled to a full refund. We
              request you to include in the email the following details -
              Transaction date and Order Number. We shall investigate the
              incident and if it is found that money was indeed charged to your
              card or bank account without delivery then you will be refunded
              the money within 15 working days from the date of the receipt of
              your email.
            </p>
            <h2 className="terms-sub-heading">Refund Policy and Process</h2>
            <p className="fs-4">
              The reservations which are applicable for refund as per the
              cancellation policy specified in each booking will be entitled for
              refund (if applicable) and will be refunded within 15 working days
              from the date of cancellation request after the deduction of
              payment gateway processing charges.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CancellationAndRefund;
