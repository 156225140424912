// import useReq from "../../../hook/useReq";

// const RaiseTicket = () => {
// //   const { error, loading, requestData, response, clear } = useReq();


//   return (
//     <>
//         <h1>Enatch Mandate</h1>
//     </>
//   );
// };

// export default RaiseTicket;


// import React, { useState, useRef } from "react";
// // import { useDropzone } from "react-dropzone"; // To handle drag-and-drop for file uploads

// const RaiseTicketForm = () => {
//   const [title, setTitle] = useState("");
//   const [technicalIssue, setTechnicalIssue] = useState("");
//   const [description, setDescription] = useState("");
//   const [files, setFiles] = useState([]);
//   const [error, setError] = useState("");

//   // const { getRootProps, getInputProps } = ({
//   //   onDrop: (acceptedFiles) => setFiles(acceptedFiles),
//   //   accept: "image/*", // Accept only image files
//   //   multiple: true, // Allow multiple files
//   // });

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!title || !technicalIssue || !description || files.length === 0) {
//       setError("Please fill in all fields and upload at least one screenshot.");
//       return;
//     }

//     // Handle form submission logic here (e.g., API call)
//     console.log("Form submitted:", { title, technicalIssue, description, files });
//     setError(""); // Clear error
//   };

//   return (
//     <div>
//       <div className="card shadow-sm p-4">
//         <form onSubmit={handleSubmit}>
//           <h4 className="mb-4">Raise a Ticket</h4>

//           {error && <p style={{ color: "red" }}>{error}</p>}

//           {/* Title Field */}
//           <div className="mb-3">
//             <label htmlFor="title" className="form-label">Title</label>
//             <input
//               type="text"
//               className="form-control"
//               id="title"
//               value={title}
//               onChange={(e) => setTitle(e.target.value)}
//               placeholder="Enter the title"
//               required
//             />
//           </div>

//           {/* Technical Issue Dropdown */}
//           <div className="mb-3">
//             <label htmlFor="technicalIssue" className="form-label">Select Technical Issue</label>
//             <select
//               className="form-select"
//               id="technicalIssue"
//               value={technicalIssue}
//               onChange={(e) => setTechnicalIssue(e.target.value)}
//               required
//             >
//               <option value="">Select an issue</option>
//               <option value="Software Issue">Software Issue</option>
//               <option value="Hardware Issue">Hardware Issue</option>
//               <option value="Network Issue">Network Issue</option>
//               <option value="Other">Other</option>
//             </select>
//           </div>

//           {/* Description Field */}
//           <div className="mb-3">
//             <label htmlFor="description" className="form-label">Description</label>
//             <textarea
//               className="form-control"
//               id="description"
//               rows="4"
//               value={description}
//               onChange={(e) => setDescription(e.target.value)}
//               placeholder="Provide a detailed description of the issue"
//               required
//             />
//           </div>

//           {/* Screenshot Uploader */}
//           {/* <div className="mb-3">
//             <label htmlFor="screenshots" className="form-label">Upload Screenshots</label>
//             <div
//               {...getRootProps()}
//               className="dropzone-container border-2 border-dashed p-4 rounded"
//               style={{ cursor: "pointer" }}
//             >
//               <input {...getInputProps()} />
//               <p>Drag & Drop your files here or click to select files</p>
//             </div>
//             <div className="mt-2">
//               {files.length > 0 && (
//                 <ul>
//                   {files.map((file, index) => (
//                     <li key={index}>{file.name}</li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//           </div> */}

//           {/* Submit Button */}
//           <button type="submit" className="btn btn-primary">
//             Submit Ticket
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default RaiseTicketForm;


import React, { useState } from "react";

const RaiseTicketForm = () => {
  const [title, setTitle] = useState("");
  const [technicalIssue, setTechnicalIssue] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");

  // Custom drag-and-drop handler
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFiles = Array.from(e.dataTransfer.files);
    const imageFiles = droppedFiles.filter((file) => file.type.startsWith("image/"));
    if (imageFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
    } else {
      setError("Only image files are allowed.");
    }
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const imageFiles = selectedFiles.filter((file) => file.type.startsWith("image/"));
    if (imageFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
    } else {
      setError("Only image files are allowed.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!title || !technicalIssue || !description || files.length === 0) {
      setError("Please fill in all fields and upload at least one screenshot.");
      return;
    }

    // Handle form submission logic here (e.g., API call)
    console.log("Form submitted:", { title, technicalIssue, description, files });
    setError(""); // Clear error
  };

  return (
    <div>
      <div className="card shadow-sm p-6">
        <form onSubmit={handleSubmit}>
          <h2 className=" fs-2 mb-4">Raise a Ticket</h2>

          {error && <p style={{ color: "red" }}>{error}</p>}

          <div className="d-flex">
          <div className="mb-3 w-50 pe-2">
            <label htmlFor="title" className="form-label">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter the title"
              required
            />
          </div>

          <div className="mb-3 w-50 ps-2">
            <label htmlFor="technicalIssue" className="form-label">Select Technical Issue</label>
            <select
              className="form-select"
              id="technicalIssue"
              value={technicalIssue}
              onChange={(e) => setTechnicalIssue(e.target.value)}
              required
            >
              <option value="">Select an issue</option>
              <option value="Software Issue">Software Issue</option>
              <option value="Hardware Issue">Hardware Issue</option>
              <option value="Network Issue">Network Issue</option>
              <option value="Other">Other</option>
            </select>
          </div>
          </div>

          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description</label>
            <textarea
              className="form-control"
              id="description"
              rows="4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Provide a detailed description of the issue"
              required
            />
          </div>

          {/* Custom Screenshot Uploader */}
          <div className="mb-3">
            <label htmlFor="screenshots" className="form-label">Upload Screenshots</label>
            <div
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              // className="dropzone-container border-2 border-dashed p-4 rounded"
              className="dropzone-container border-2 border-dashed p-4 rounded d-flex flex-column align-items-center justify-content-center"
              style={{ cursor: "pointer" }}
            >
              <p>Drag & Drop your files here or click to select files</p>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileInputChange}
                style={{ display: "none" }}
              />
              <button
                type="button"
                onClick={() => document.querySelector('input[type="file"]').click()}
                className="btn text-white mt-2"
                style={{ backgroundColor: "#0a5c7b" }}
              >
                Select Files
              </button>
            </div>
            <div className="mt-2">
              {files.length > 0 && (
                <ul>
                  {files.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Submit Button */}
          <button type="submit" className="btn text-white staticgreen"  >
            Submit Ticket
          </button>
        </form>
      </div>
    </div>
  );
};

export default RaiseTicketForm;
