import React, { useContext, useEffect, useState } from "react";
import useReq from "../../../hook/useReq";

const TransactionDetails = () => {
    const { response, requestData } = useReq();
    const [transactionData, setTransactionData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const transactionsPerPage = 10;

    useEffect(() => {
        requestData("GET", "/user/transactions");
    }, []);

    useEffect(() => {
        if (response) {
            setTransactionData(response.transactions);
            console.log("Response", response);
        }
    }, [response]);

    const totalPages = Math.ceil(transactionData.length / transactionsPerPage);
    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions = transactionData.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pages = [];
        if (totalPages <= 4) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1);
            if (currentPage > 3) pages.push('...');

            const start = Math.max(2, currentPage - 1);
            const end = Math.min(totalPages - 1, currentPage + 1);
            for (let i = start; i <= end; i++) {
                pages.push(i);
            }

            if (currentPage < totalPages - 2) pages.push('...');
            pages.push(totalPages);
        }
        return pages;
    };


    const getStatusClass = (status) => {
        switch (status) {
            case 'AUTHORIZATION_FAILED':
                return 'badge-light-danger text-danger';
            case 'CHARGED':
                return 'badge-light-success text-success';
            case 'initiated':
                return 'badge-light-primary text-primary';
            case 'PENDING_VBV':
                return 'badge-light-warning text-warning';
            default:
                return 'badge-light-secondary text-secondary';
        }
    };

    return (
        <div className="my-5">
            <div className="card p-4 shadow-sm rounded">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="m-0 fw-bold fs-1 mb-1">Transaction History</h5>
                    {/* <div>
                        <button className="btn btn-outline-secondary me-2">Choose Date</button>
                        <button className="btn btn-dark">Filter</button>
                    </div> */}
                </div>

                <div className="table-responsive">
                    <table className="table table-hover align-middle">
                        <thead className="bg-gray-200i">
                            <tr className="fs-2 font-bold border-top border-bottom">
                                <th className="w-15">No.</th>
                                <th className="w-15">Order Id</th>
                                <th className="w-15">Project</th>
                                <th className="w-15">Amount</th>
                                <th className="w-10">Trxn Type</th>
                                <th className="w-15">Status</th>
                                {/* <th className="w-10">Active</th> */}
                                <th className="w-10">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentTransactions.map((tx, index) => (
                                <tr key={index} className=" border-top border-bottom">
                                    <td className=" text-dark fs-4">{index + 1}</td>
                                    <td className="d-flex align-items-center">
                                        <span className=" text-dark fs-4">{tx.order_id}</span>
                                    </td>
                                    <td className=" text-dark fs-4">{tx.project.projectName}</td>
                                    <td className="text-truncate">
                                        <span className=" text-dark fs-4">{tx.txn_amount ? tx.txn_amount : '---'}</span>
                                    </td>
                                    <td className="text-truncate text-dark fs-4">{tx.txn_flow_type ? tx.txn_flow_type : '---'}</td>

                                    <td className="text-truncate">
                                        <span className={`badge ${getStatusClass(tx.status)}`}>
                                            {tx.status}
                                        </span>
                                    </td>
                                    
                                    {/* <td className="text-truncate">
                                        <span className=" text-dark fs-4">{tx.isActive ? "active" : "Inactive"}</span>
                                    </td> */}
                                    <td className="text-dark fs-4">
                                        <span>{new Date(tx.modifiedAt).toISOString().slice(0, 10)}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

                {/* Pagination */}
                <nav aria-label="Transaction pagination" className="mt-4">
                    <ul className="pagination justify-content-center">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>«</button>
                        </li>
                        {renderPageNumbers().map((page, index) => (
                            <li key={index} className={`page-item ${page === currentPage ? 'active ' : ''}`}>
                                {page === '...' ? (
                                    <span className="page-link">{page}</span>
                                ) : (
                                    <button className="page-link" onClick={() => handlePageChange(page)}>
                                        {page}
                                    </button>
                                )}
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>»</button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default TransactionDetails;
