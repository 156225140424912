import React from "react";
import Footer from "../Home/Footer";

function ContactUs(props) {
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column justify-content-between"
    >
      <div class="container d-flex flex-1 py-20 px-10 px-lg-20">
        <div class="card">
          <div class="terms-heading text-center ">
            <h1 className="text-light">Contact Us</h1>
          </div>
          <div className="card-body">

<h2 className="terms-sub-heading">
    Complaints and Grievance Redressal
  </h2>
  <div className="" >
    <p className="fs-4">
      We take your feedback, complaints, and questions seriously. If
      you contact us, we will record any Personal Information and
      other content provided in your communication to effectively
      address your concerns. In case of any complaints or concerns
      regarding your Personal Information, content of the app, or any
      disputes, breaches of confidentiality, proprietary rights, or
      intellectual property during the use of the app, please contact
      our Grievance Officer at the following details:
    </p>
    <p className="fs-4">
      <strong>Grievance Officer : </strong> Kanchan Sidhaye
    </p>
    <p className="fs-4">
      <strong>Contact Number : </strong> 9423506729
    </p>
    <p className="fs-4">
      <strong>Email Address : </strong>
      <a href="mailto:ksidhaye@vke-environmental.com">
        ksidhaye@vke-environmental.com
      </a>
    </p>
    <p className="fs-4">
      You may also raise a grievance ticket through the app's support
      section.
    </p>
  </div>
</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
