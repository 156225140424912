import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UsersContext } from "../../context/usersContext";
import useReq from "../../hook/useReq";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import emptyProject from "../../assets/empty.jpg";
import Swal from "sweetalert2";
import LazyImage from "../../components/LazyImage";

const Projects = () => {
  const ctx = useContext(UsersContext);
  const navigate = useNavigate();
  const { socket } = ctx;
  const [data, setData] = useState(null);
  const [projectName, setprojectName] = useState("");
  const [description, setdesc] = useState("");
  const [file, setFile] = useState(null);
  const [projectCreationError, setProjectCreationERROR] = useState("");
  const fileInputRef = useRef(null);
  const [isProjectCreationStarted, setIsProjectCreationStarted] =
    useState(false);

  const { response, requestData, error } = useReq();
  const {
    response: responseCreateProject,
    requestData: requestCreateProject,
    error: errorCreateProject,
  } = useReq();

  const playSound = (data) => {
    if (!data.lastMessage.status) {
      // const audioElement = new Audio("/assets/sound/notify.mp3");
      // audioElement.play();
    }
  };
  // useEffect(() => {
  //   if (socket) {
  //     socket.on("forSidebar", playSound);

  //     return () => {
  //       socket.off("forSidebar", playSound);

  //     };
  //   }
  // }, [socket, ctx]);

  // Handle create project
  const handleprojectcreate = (e) => {
    setIsProjectCreationStarted(true);
    e.preventDefault();
    if (!projectName || projectName.trim().length < 8) {
      setProjectCreationERROR("Project Name should be more than 8 characters");
      setIsProjectCreationStarted(false);
      return;
    } else if (description.trim().length > 150) {
      setProjectCreationERROR(
        "Project Description must be less than 150 characters"
      );
      setIsProjectCreationStarted(false);
      return;
    } else if (!file) {
      setProjectCreationERROR("Please select an image for Project ");
      setIsProjectCreationStarted(false);
      return;
    } else {
      setProjectCreationERROR("");
    }

    const formData = new FormData();
    formData.append("projectName", projectName.trim());
    formData.append("description", description.trim());
    formData.append("image", file);
    setprojectName();
    setdesc();
    setFile();
    requestCreateProject("POST", "/user/createproject", formData);
  };

  // Response TO Create Project
  useEffect(() => {
    if (responseCreateProject !== null && responseCreateProject.project) {
      setIsProjectCreationStarted(false);
      const projectClose = document.getElementById("createprojectmodel");
      if (projectClose) {
        projectClose.click();
      }
      requestData("GET", "/user/getprojects");
      Swal.fire({
        title: "Project Created Successfully!",
        text: "",
        icon: "success",
        iconColor: "#0a5c7b",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [responseCreateProject]);

  // Creating project loader
  useEffect(() => {
    if (isProjectCreationStarted === true) {
      Swal.fire({
        title: "",
        html: `<div className="tick ccc-z-index">
        <div className="tick-mark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150px"
            height="150px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              stroke="#49798d"
              stroke-width="6"
              r="32"
              stroke-dasharray="174.35839227423352 60.119464091411174"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="2.0408163265306123s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </div>
        <h2>Creating project... Please wait.</h2>
      </div>`,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [isProjectCreationStarted]);

  // Create Project Error
  useEffect(() => {
    if (errorCreateProject) {
      Swal.fire({
        title: "Failed to create Project.",
        text: "",
        icon: "error",
        iconColor: "red",
        showConfirmButton: true,
        confirmButtonText: "Try Again..",
        confirmButtonColor: "#0a5c7b",
      });
    }
  }, [errorCreateProject]);

  // Get all projects response
  useEffect(() => {
    if (response !== null && response.projects) {
      setData(response.projects);
    }
  }, [response]);

  // Fetch All projects error

  useEffect(() => {
    if (error) {
      const message = typeof error === "string" ? error : error.message;
      Swal.fire({
        title: "Error fetching projects",
        text: `${message}`,
        icon: "error",
        iconColor: "red",
        showConfirmButton: true,
        confirmButtonText: "Try Again..",
        confirmButtonColor: "#0a5c7b",
        preConfirm: fetchProjectsAgain,
        cancelButtonColor: "red",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      });
    }
  }, [error]);

  function fetchProjectsAgain() {
    requestData("GET", "/user/getprojects");
  }

  const pickedHandler = (event) => {
    let pickedFile;

    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        const elements = document.querySelectorAll(".image-input-placeholder");

        // Set the new background image using the data URL
        elements.forEach((element) => {
          element.style.backgroundImage = "url(" + imageDataUrl + ")";
        });
      };

      reader.readAsDataURL(pickedFile);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("projectid");
    requestData("GET", "/user/getprojects");
  }, []);

  const handleDivClick = () => {
    // Trigger the file input programmatically
    fileInputRef.current.click();
  };

  const handleInputChange = (event) => {
    let pickedFile;

    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        const elements = document.querySelectorAll(".image-input-placeholder");

        // Set the new background image using the data URL
        elements.forEach((element) => {
          element.style.backgroundImage = "url(" + imageDataUrl + ")";
        });
      };

      reader.readAsDataURL(pickedFile);
    }
  };

  return (
    <>
      <div className="col-xl-12 ps-xl-12">
        <div className="card ">
          <div className="card-header border-0 pt-5">
            <h2 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-1 mb-1">All Projects</span>
              <span className="text-muted mt-1 fw-semibold fs-7">
                List of all the projects with all it's details
              </span>
            </h2>
          </div>
          {/* <div className="card-body py-3 Projects_page-content"> */}
          <div className="card-body">
            <div className=" py-3">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="kt_table_widget_5_tab_1"
                >
                  {!data && (
                    <div className="d-flex">
                      <Skeleton
                        containerClassName="flex-1"
                        count={5}
                        height={40}
                      />
                      <Skeleton
                        containerClassName="flex-1"
                        count={5}
                        height={40}
                      />
                      <Skeleton
                        containerClassName="flex-1"
                        count={5}
                        height={40}
                      />
                      <Skeleton
                        containerClassName="flex-1"
                        count={5}
                        height={40}
                      />
                      <Skeleton
                        containerClassName="flex-1"
                        count={5}
                        height={40}
                      />
                      {/* <Skeleton
                                        variant="text"
                                        sx={{ fontSize: "1rem" }}
                                        width={60}
                                        containerClassName="align-item-center"
                                      />

                                      <Skeleton
                                        variant="circular"
                                        width={150}
                                        height={40}
                                        containerClassName="mx-10"
                                      />
                                      <Skeleton
                                        variant="rectangular"
                                        width={210}
                                        height={60}
                                        containerClassName="mx-10"
                                      />
                                      <Skeleton
                                        variant="rounded"
                                        width={210}
                                        height={60}
                                        containerClassName="mx-10"
                                      /> */}
                    </div>
                  )}
                  {data && data.length === 0 && (
                    <div style={{ fontSize: "14px" }}>
                      <div className=" d-flex justify-content-center ">
                        <img
                          src={emptyProject}
                          alt="Empty"
                          style={{ width: "20%" }}
                        />
                      </div>
                      <div
                        className="d-flex justify-content-center "
                        style={{
                          width: "100%",
                          overflow: "scroll",
                        }}
                      >
                        <b>
                          No Project.
                          <Link
                            className="ProjLink"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_new_target"
                          >
                            Create New
                          </Link>
                        </b>
                      </div>
                      {/* <div>
                                    <div className="m-0 ">
                                <Link
                                  // className="btn btn-dark mx-5 px-6 py-3 my-1 hover-scale"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kt_modal_new_target"
                                >
                                  Create Project
                                </Link>
                              </div>
                                    </div> */}
                    </div>
                  )}
                  <div className="">
                    <div className=" p-4 align-items-left fw-bold text-1 text-muted bg-collabworx rounded my-2 d-none d-md-flex">
                      <div className="col-3 col-md-1 text-center d-none d-md-block"><h3>Icon</h3></div>

                      <div className="col col-md-3 d-none d-md-block"><h3>Name</h3></div>

                      <div className="d-none d-md-block col-md-2"><h3>Project Code</h3></div>

                      <div className="d-none d-md-block col-md-3"><h3>Description</h3></div>

                      <div className="d-none d-md-block col-md-1"><h3>Size</h3></div>
                      <div className="col-2 col-md-1 text-center d-none d-md-block"><h3>Status</h3></div>

                      <div className="d-none d-md-block col-md-1 text-end"></div>
                    </div>
                    {/* <hr className="my-2 d-none d-md-block"></hr> */}


                    {data &&
                      data.map((project, index) => (
                        <Link to={"/project/" + project._id} key={project._id}>
                          {/* <div className="row p-4 align-items-center hover-elevate-up bg-hover-light  bg-light-gray d-flex projects-card overflow-hidden hover-heading-color my-2"> */}
                          <div
                            className={`row p-4 align-items-center hover-elevate-up ${index % 2 === 0 ? "bg-white" : "bg-light-gray"
                              } d-flex projects-card overflow-hidden hover-heading-color my-2`}
                          >
                            {/* First Div */}
                            <div className="col-3 col-md-1  text-md-start text-lg-center me-2 me-md-0">
                              <LazyImage
                                style="img-cover h-60px w-60px rounded-1"
                                src={
                                  process.env.REACT_APP_SOCKETIO + project.logo
                                }
                              />
                              {/* <div className="symbol symbol-45px me-2"> 
                                <span className="symbol-label">
                                  <img
                                    className="mw-50px mw-lg-45px"
                                    style={{
                                      maxHeight: "75px",
                                      borderRadius: "4px",
                                      maxWidth: "80px",
                                    }}
                                    src={
                                      process.env.REACT_APP_SOCKETIO +
                                      project.logo
                                    }
                                    alt="logo"
                                  />
                                </span>
                              </div> */}
                            </div>
                            {/* Second Div */}

                            <div className="col col-md-3 ">
                              <div className="d-flex">
                                <div className="text-dark fw-bold mb-1 fs-4 heading-color">
                                  {/* <Link
                                                              to={
                                                                "/project/" +
                                                                project._id
                                                              }
                                                              className="text-dark fw-bold mb-1 fs-4 bluecolorhover"
                                                            > */}
                                  {/* {project.name} */}
                                  {project.name.length > 18
                                    ? project.name.substring(0, 16) + "..."
                                    : project.name}
                                  {project.status === "Active" ? (
                                    <span
                                      className={
                                        "badge mx-3 " +
                                        (project.projectAdminUser.email ===
                                          ctx.email
                                          ? "staticgreen"
                                          : "staticblue")
                                      }
                                    >
                                      {project.projectAdminUser.email ===
                                        ctx.email
                                        ? "Host"
                                        : "Member"}
                                    </span>
                                  ) : (
                                    <span
                                      className={
                                        "badge mx-3 " +
                                        (project.projectAdminUser.email ===
                                          ctx.email
                                          ? "badge-light-warning"
                                          : "badge-light-dark")
                                      }
                                    >
                                      {project.projectAdminUser.email ===
                                        ctx.email
                                        ? "Host"
                                        : "Member"}
                                    </span>
                                  )}
                                  {/* </Link> */}
                                  <span className="text-muted fs-7 fw-semibold d-block d-md-none mt-2">
                                    {project.projectCode}
                                  </span>
                                  <span className="text-muted fs-7 fw-semibold d-block">
                                    Commercial Project
                                  </span>
                                </div>
                                <div className="ms-auto d-block d-md-none">
                                  {project.status === "Active" ? (
                                    <>
                                      <span className="badge badge-light-success">
                                        {project.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge badge-light-danger">
                                        {project.status}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Third Div */}
                            <div className="d-none d-md-block col-md-2  fs-6 fw-bold text-muted">
                              {project.projectCode}
                            </div>

                            {/* forth Div */}
                            <div className="d-none d-md-block col-12 col-md-3 fs-6 fw-bold text-muted">
                              {project.description.length > 25
                                ? project.description.substring(0, 22) + "..."
                                : project.description}
                            </div>
                            {/* fifth Div */}
                            <div className="d-none d-md-block col-12 col-md-1 fs-6 fw-bold text-muted">
                              2.5 GB
                            </div>
                            

                            {/* sixth Div */}
                            <div className="col-2 col-md-1 d-none d-md-block">
                              {project.status === "Active" ? (
                                <>
                                  <span className="badge badge-light-success">
                                    {project.status}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="badge badge-light-danger">
                                    {project.status}
                                  </span>
                                </>
                              )}
                            </div>

                            {/* Seventh div */}
                            <div className="d-none d-md-block col-sm-2 col-md-1 text-end">
                              <img
                                src="/assets/icons/right-arrow.png"
                                height={20}
                                width={20}
                                className=" bluecolorhover me-2 me-lg-6"
                              />
                            </div>
                          </div>
                          <hr className="my-2 d-none d-md-block"></hr>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="kt_modal_new_target"
        tabIndex={-1}
        aria-hidden="true"
        style={{ backdropFilter: "brightness(0.7)" }}
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content rounded">
            <div className="modal-header pb-0 border-0 justify-content-end">
              <div
                className="btn btn-sm btn-icon"
                id="createprojectmodel"
                data-bs-dismiss="modal"
                onClick={() => {
                  setProjectCreationERROR((prev) => "");
                  setFile(null);
                  setprojectName("");
                  setdesc("");
                  const elements = document.querySelectorAll(
                    ".image-input-placeholder"
                  );

                  // Set the new background image using the data URL
                  elements.forEach((element) => {
                    element.style.backgroundImage = "";
                  });
                }}
              >
                <img
                  src="/assets/icons/close-blue.png"
                  className="me-2"
                  height={16}
                  alt="view files"
                  width={16}
                />
              </div>
            </div>
            <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
              <form
                // id="kt_modal_new_target_form"
                className="form"
                onSubmit={handleprojectcreate}
              >
                <div className="mb-13 text-center">
                  <h1 className="mb-3">Create New Project</h1>
                  <div className="text-muted fw-semibold fs-5">
                    If you need more info, please check{" "}
                    <a href=" " className="fw-bold greencolor">
                      Project Guidelines
                    </a>
                    .
                  </div>
                </div>
                <div className="d-flex flex-column mb-8 fv-row">
                  <>
                    {projectCreationError && (
                      <p className="mb-3 text-danger fs-5">
                        {projectCreationError} *
                      </p>
                    )}
                  </>
                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span className="required">Project Name</span>
                    {/* <i
                        className="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        title="Specify a project name as per the requirement"
                      /> */}
                  </label>
                  <input
                    type="text"
                    value={projectName}
                    onChange={(e) => {
                      setprojectName(e.target.value);
                      setProjectCreationERROR((prev) => "");
                    }}
                    className="form-control form-control-solid"
                    placeholder="Enter Project Name"
                    name="projectname"
                    required
                  />
                </div>
                <div className="d-flex flex-column mb-8">
                  <label className="fs-6 fw-semibold mb-2">
                    Project Description
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    value={description}
                    onChange={(e) => {
                      setdesc(e.target.value);
                      setProjectCreationERROR((prev) => "");
                    }}
                    rows={3}
                    name="target_details"
                    placeholder="Enter Project Description"
                    required
                  />
                </div>
                <div className="fv-row mb-10">
                  <label className="d-block fw-semibold fs-6 mb-5">
                    <span className="required">Project Logo</span>
                    {/* <i
                        className="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        title="Upload Project Logo"
                      /> */}
                  </label>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".image-input-placeholder { background-image: url('assets/media/svg/files/blank-image.svg'); } [data-bs-theme=\"dark\"] .image-input-placeholder { background-image: url('assets/media/svg/files/blank-image-dark.svg'); }",
                    }}
                  />
                  <div
                    className="image-input image-input-empty image-input-outline image-input-placeholder"
                    data-kt-image-input="true"
                  >
                    {/* begin::Preview existing avatar*/}
                    <div
                      className="image-input-wrapper w-125px h-125px"
                      onClick={handleDivClick}
                      style={{ cursor: "pointer" }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleInputChange}
                      ref={fileInputRef}
                      style={{ display: "none" }} // hide the input field
                    />

                    <label
                      className="btn btn-icon btn-circle  w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Upload Project Logo"
                    >
                      <input
                        type="file"
                        name="avatar"
                        onChange={(e) => pickedHandler(e)}
                        accept="image/*"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="currentColor"
                        className="bi bi-pencil-fill fs-7"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                      </svg>
                      <input type="hidden" name="avatar_remove" />
                    </label>
                    <span
                      className="btn btn-icon btn-circle  w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="cancel"
                      data-bs-toggle="tooltip"
                      title="Cancel Project Logo"
                    >
                      <i className="bi bi-x fs-2" />
                    </span>
                    <span
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      title="Remove Project Logo"
                    >
                      <i className="bi bi-x fs-2" />
                    </span>
                  </div>
                  <div className="form-text">
                    Allowed file types: png, jpg, jpeg.
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="reset"
                    id="kt_modal_new_target_cancel"
                    className="btn me-5 staticblue hover-scale"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setProjectCreationERROR((prev) => "");
                      setFile(null);
                      setprojectName("");
                      setdesc("");
                      const elements = document.querySelectorAll(
                        ".image-input-placeholder"
                      );
                      elements.forEach((element) => {
                        element.style.backgroundImage = "";
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn staticgreen hover-scale">
                    {/* <span className="indicator-label"> */}
                    Create &amp; Send Invite
                    {/* </span> */}
                    {/* <span className="indicator-progress"> */}
                    {/* Please wait... */}
                    {/* <span className="spinner-border spinner-border-sm align-middle ms-2" /> */}
                    {/* </span> */}
                  </button>
                  {/* <button
                      type="submit"
                      id="kt_modal_new_target_submit"
                      className="btn btn-primary"
                    >
                      <span className="indicator-label"></span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
