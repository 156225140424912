import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import useReq from "../../../hook/useReq";
import { GoDownload } from "react-icons/go";

const PaymentSuccess = () => {
  const { error, loading, requestData, response, clear } = useReq();

  const location = useLocation();
  const navigate = useNavigate();
  const [paymentDetails, setPaymentDetails] = useState(null);

  const orderId = new URLSearchParams(location.search).get("orderId");

  useEffect(() => {
    if (orderId) {
      requestData("POST", "/user/handleJuspayResponse", { orderId });
    }
  }, [orderId]);

  useEffect(() => {
    if (response) {
      setPaymentDetails(response);
      Swal.close();
    }
  }, [response]);

  console.log("paymentDetailsmain", paymentDetails?.data.trxnDetails);
  console.log("paymentDetails", paymentDetails?.data.trxnDetails.status);

  // Loading
  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: "",
        html: `<div className="tick ccc-z-index">
          <div className="tick-mark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="150px"
              height="150px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#49798d"
                stroke-width="6"
                r="32"
                stroke-dasharray="174.35839227423352 60.119464091411174"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="2.0408163265306123s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                ></animateTransform>
              </circle>
            </svg>
          </div>
          <h2>fetching payment</h2>
        </div>`,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loading]);

  if (error) {
    const getErrorMessage = (error) => {
      if (typeof error === "object" && error !== null && "message" in error) {
        return error.message;
      }
      return error;
    };

    Swal.close();

    return (
      <div className="container">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="col-md-7 card p-10">
            <div className="error-container d-flex justify-content-center align-items-center row">
              <div className="w-100px mb-10">
                <img
                  src="/assets/icons/wrong.png"
                  alt="Error Image"
                  className="w-100"
                />
              </div>
              <div className="text-center mb-10">
                <h1 className="fs-1 mb-10">{getErrorMessage(error)}</h1>
                <p className="fs-4 ">
                  We couldn't fetch your payment data. If any amount was deducted, it will be refunded.
                </p>
              </div>
              <Link
                to="/signin"
                className="btn staticblue  w-50 mt-10 mt-md-0 d-flex justify-content-center align-items-center gap-1 mt-10"
              >
                Go Back
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const isSuccess =
  paymentDetails?.data.trxnDetails.status.toLowerCase() ===
    "charged";

    {console.log("paymentDetails")}

  const generatePDF = () => {
    const doc = new jsPDF();

    const logo = "/assets/media/logos/collabworx-logo.png";
    // const companyName = 'Your Company Name';

    const receiptContent = `
    Payment Receipt

    Invoice Number:  ${paymentDetails.id}
    Date:                 ${new Date(
      paymentDetails.date_created
    ).toLocaleDateString()}
    Amount:           ${paymentDetails.amount} ${paymentDetails.currency}

    Bill To:
    Customer ID:        ${paymentDetails.customer_id}
    Phone:              ${paymentDetails.customer_phone}

    --------------------------------------------------

    Description                    Amount
    --------------------------------------------------
    Transaction ID                 ${paymentDetails.txn_id}
    Payment Method                 ${paymentDetails.payment_method}
    Status                         ${paymentDetails.status}
    Response Code                  ${
      paymentDetails.payment_gateway_response.resp_code
    }
    Response Message               ${
      paymentDetails.payment_gateway_response.resp_message
    }

    --------------------------------------------------

    TOTAL                          ${paymentDetails.amount} ${
      paymentDetails.currency
    }

    If you have any questions, feel free to contact us at support@example.com.
  `;

    // Set font and text size
    doc.setFont("helvetica");
    doc.setFontSize(12);

    // Add logo
    doc.addImage(logo, "PNG", 20, 20, 40, 20);

    // Add company name
    // doc.text(companyName, 60, 30); // Adjust position as needed

    // Add receipt content
    doc.text(receiptContent, 20, 50); // Adjust position as needed

    // Save the PDF
    doc.save("payment_receipt.pdf");
  };

  const handleprojects = () => {
    navigate("../projects");
  };

  return (
    <>
     
      {paymentDetails && (
        <>
          <div className="container p-6 max-w-xl w-50 bottom-7">
            <div className="d-flex flex-column justify-content-around align-items-center h-100">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img
                  src={
                    isSuccess
                      ? "/assets/icons/success.png"
                      : "/assets/icons/failed.png"
                  }
                  alt={isSuccess ? "Success" : "Failed"}
                  width={135}
                  height={130}
                />
                <h1
                  className="fs-1 font-weight-bold mb-4"
                  style={{
                    color: "black",
                    textAlign: "center",
                    justifyItems: "center",
                    marginTop: 20,
                  }}
                >
                  {paymentDetails?.data.trxnDetails.payment_gateway_response?.resp_message.toUpperCase()}
                  !
                </h1>
              </div>
              <div className="d-flex flex-column w-90 pt-9 position-relative move-upward">
                <div className="d-flex innerdiv flex-column gap-4 p-6">
                  <div className="d-flex justify-content-between mb-3">
                    <span style={{ textAlign: "left", fontSize: "24px" }}>
                      Payment Receipt
                    </span>
                    <button
                      onClick={generatePDF}
                      className="btn-lg download-btn"
                    >
                      <GoDownload className="download-icon" />
                      <span className="download-text fs-5">Download</span>
                    </button>
                  </div>
                  <hr />
                  <div>
                    <div className="d-flex flex-row flex-md-row align-items-start align-items-md-center mb-2">
                      <span
                        className="text-muted font-weight-light"
                        style={{ width: "150px", paddingRight: "10px" }}
                      >
                        Customer ID:
                      </span>
                      <span className="text-dark">
                        {paymentDetails?.data.trxnDetails.customer_id}
                      </span>
                    </div>
                    <div className="d-flex flex-row flex-md-row align-items-start align-items-md-center mb-2">
                      <span
                        className="text-muted font-weight-light"
                        style={{ width: "150px", paddingRight: "10px" }}
                      >
                        Name:
                      </span>
                      <span className="text-dark">
                        {paymentDetails?.data.trxnDetails.txn_detail?.txn_amount_breakup[0].name}
                      </span>
                    </div>
                    <div className="d-flex flex-row flex-md-row align-items-start align-items-md-center mb-2">
                      <span
                        className="text-muted font-weight-light"
                        style={{ width: "150px", paddingRight: "10px" }}
                      >
                        Amount:
                      </span>
                      <span className="text-dark">
                        {paymentDetails?.data.trxnDetails.amount} {paymentDetails?.data.trxnDetails.currency}
                      </span>
                    </div>
                    <div className="d-flex flex-row flex-md-row align-items-start align-items-md-center mb-2">
                      <span
                        className="text-muted font-weight-light"
                        style={{ width: "150px", paddingRight: "10px" }}
                      >
                        Order ID:
                      </span>
                      <span className="text-dark">
                        {paymentDetails?.data.trxnDetails.order_id}
                      </span>
                    </div>
                    <div className="d-flex flex-row flex-md-row align-items-start align-items-md-center mb-2">
                      <span
                        className="text-muted font-weight-light"
                        style={{ width: "150px", paddingRight: "10px" }}
                      >
                        Transaction Date:
                      </span>
                      <span className="text-dark">
                        {new Date(paymentDetails?.data.trxnDetails.date_created).toLocaleString()}
                      </span>
                    </div>
                    <div className="d-flex flex-row flex-md-row align-items-start align-items-md-center mb-2">
                      <span
                        className="text-muted font-weight-light"
                        style={{ width: "150px", paddingRight: "10px" }}
                      >
                        Payment Mode:
                      </span>
                      <span className="text-dark">
                        {paymentDetails?.data.trxnDetails.payment_method}
                      </span>
                    </div>
                    <div className="d-flex flex-row flex-md-row align-items-start align-items-md-center mb-2">
                      <span
                        className="text-muted font-weight-light"
                        style={{ width: "150px", paddingRight: "10px" }}
                      >
                        Transaction ID:
                      </span>
                      <span className="text-dark">
                        {paymentDetails?.data.trxnDetails.txn_id}
                        {console.log(
                          "paymentDetails?.data.trxnDetails.txn_id",
                          paymentDetails?.data.trxnDetails.txn_id
                        )}
                      </span>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-center align-items-center mb-7 mt-10 signinbtn-container">
                    <button
                      onClick={handleSignin}
                      className="text-md text-center signinbtn "
                    >
                      SignIn
                    </button>
                  </div> */}

                  <div className="d-flex justify-content-center align-items-center mt-6">
                    <div className="d-flex justify-content-between mb-3">
                      <button
                        onClick={handleprojects}
                        className="btn-lg download-btn"
                      >
                        <span className="download-text fs-5">Go to projects</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex logo justify-content-center align-items-center mb-7 mt-8 footer-logo">
            <span style={{ color: "black" }} className="text-md text-center">
              Powered by
            </span>

            <img
              src="/assets/media/logos/collabworx-logo.png"
              alt="Collabworx"
              width={200}
              className="m-2"
            />
          </div>
        </>
      )}
    </>
  );
};

export default PaymentSuccess;
