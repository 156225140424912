import React, { Suspense, useContext, useEffect, useState } from "react";
import cn from "classnames";
import { UsersContext } from "../../context/usersContext";
import Loader from "../../components/Loader";
import UserDetails from "./components/UserDetails";
import TransactionDetails from "./components/TransactionDetails";

const MyAccountWraper = () => {
  const ctx = useContext(UsersContext);
  const [myAccount, setMyAccount] = useState(true);
  const [tranTab, setTranTab] = useState(false);




  const handleClick1 = (e) => {
    const targetElementID = e.target.id;
    if (targetElementID === "user") {
      setMyAccount(true);
      setTranTab(false);

    }
    if (targetElementID === "trans") {
      setMyAccount(false);
      setTranTab(true);
    }
  }


  console.log("myAccount", myAccount);
  console.log("tranTab", tranTab);


  return (
    <div>
      <div id="zindex" className="card mb-8 mb-xl-9">
        <div className="card-body p-2 px-6  pb-0 pt-5">

          {/* <div className="d-flex">
            <div className="">
              <img src={process.env.REACT_APP_SOCKETIO + ctx.avatar} alt="" className="w-40px h-35px" />
            </div>
            <div>
              <h1>{ctx.username}</h1>
              <p>{ctx.role}</p>
            </div>
          </div> */}



          <div className="d-flex align-items-center p-2 mb-4">
            {/* Left side: Image */}
            <div className="flex-shrink-0">
              <img
                src={process.env.REACT_APP_SOCKETIO + ctx.avatar}
                alt="Thumbnail"
                className="rounded-3"
                style={{ width: '6rem', height: '6rem', objectFit: 'cover' }}
              />
            </div>

            {/* Right side: Text content */}
            <div className="ms-4">
              <h2 className="fs-2 mb-2">{ctx.username}</h2>
              <p className="d-flex align-items-center mb-1">
                {ctx.role}
              </p>

            </div>
          </div>



          <div className="separator" />
          <div>
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
              <li className="nav-item">
                <button
                  id="user"
                  className={cn(
                    "nav-link text-active-primary py-2 me-6 fs-4 fw-bold",
                    myAccount && "active staticactive"
                  )}
                  onClick={handleClick1}
                >
                  My Account
                </button>
              </li>
              <li className="nav-item">
                <button
                  id="trans"
                  className={cn(
                    "nav-link text-active-primary py-2 me-3 fs-4 fw-bold",
                    tranTab && "active staticactive"
                  )}
                  onClick={handleClick1}
                >
                  Transactions
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="">
            {myAccount && (
              <Suspense fallback={<Loader />}>
                <UserDetails />
              </Suspense>
            )}
            {tranTab && (
              <UsersContext.Provider>
                <Suspense fallback={<Loader />}>
                  <TransactionDetails />
                </Suspense>
              </UsersContext.Provider>
            )}
          </div>
    </div>
  );
};

export default MyAccountWraper;
