// import React from 'react';
// // import useReq from "../../../hook/useReq";


// const RazorpayPayment = () => {
//     const amount = 5;
//     const currency = "INR";
//     const receiptId = "qwsaq1";
    
  
//     const paymentHandler = async (e) => {
// //   const { error, loading, requestData, response, clear } = useReq();





//       const response = await fetch("http://localhost:5000/order", {
//         method: "POST",
//         body: JSON.stringify({
//           amount: Number(amount * 100),
//           currency,
//           receipt: receiptId,
//         }),
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       const order = await response.json();
//       console.log(order);
  
//       var options = {
//         key: "rzp_live_N9VUz5rG7Y1ouX", 
//         amount,
//         currency,
//         name: "Acme Corp",
//         description: "Test Transaction",
//         image: "https://example.com/your_logo",
//         order_id: order.id, 
//         handler: async function (response) {
//           const body = {
//             ...response,
//           };
  
//           const validateRes = await fetch(
//             "http://localhost:5000/order/validate",
//             {
//               method: "POST",
//               body: JSON.stringify(body),
//               headers: {
//                 "Content-Type": "application/json",
//               },
//             }
//           );
//           const jsonRes = await validateRes.json();
//           console.log(jsonRes);
//         },
//         prefill: {
//           name: "Vaibhav", 
//           email: "example@example.com",
//           contact: "9000000000", 
//         },
//         notes: {
//           address: "Razorpay Corporate Office",
//         },
//         theme: {
//           color: "#3399cc",
//         },
//       };
//       var rzp1 = new window.Razorpay(options);
//       rzp1.on("payment.failed", function (response) {
//         alert(response.error.code);
//         alert(response.error.description);
//         alert(response.error.source);
//         alert(response.error.step);
//         alert(response.error.reason);
//         alert(response.error.metadata.order_id);
//         alert(response.error.metadata.payment_id);
//       });
//       rzp1.open();
//       e.preventDefault();
//     };

//     return (
       
//         <div>
//         <button onClick={paymentHandler}>Pay</button>
//       </div>
//     );
// };

// export default RazorpayPayment;



import {React, useEffect} from 'react';
import useReq from "../../../../hook/useReq";

const RazorpayPayment = () => {
    // const amount = 1;
    // const currency = "INR";
    // const receiptId = "qwsaq1";
    
    const { error, loading, requestData, response, clear } = useReq();

    const paymentHandler = async (e) => {
        e.preventDefault();

        clear();

        // const payload = {
        //     amount: Number(amount * 100),
        //     currency,
        //     receipt: receiptId,
        // };

        requestData("POST", "/user/create-order");

        
    };

    useEffect(() => {
        if(response) {
            if (response) {
                console.log(response);
                var options = {
                    key: "rzp_live_N9VUz5rG7Y1ouX",
                    // amount,
                    // currency,
                    name: "Acme Corp",
                    description: "Test Transaction",
                    image: "https://example.com/your_logo",
                    order_id: response.id,
                    handler: async function (paymentResponse) {
                        const validatePayload = {
                            ...paymentResponse,
                        };
    
                        requestData("POST", "/user/handle-order", validatePayload);
                        if (response) {
                            console.log(response);
                        }
                    },
                    // callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
                    prefill: {
                        name: "Vaibhav",
                        email: "example@example.com",
                        contact: "9000000000",
                    },
                    notes: {
                        address: "Razorpay Corporate Office",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };
    
                var rzp1 = new window.Razorpay(options);
                rzp1.on("payment.failed", function (response) {
                    alert(response.error.code);
                    alert(response.error.description);
                    alert(response.error.source);
                    alert(response.error.step);
                    alert(response.error.reason);
                    alert(response.error.metadata.order_id);
                    alert(response.error.metadata.payment_id);
                });
                rzp1.open();
            }
        }
    }, [response]);

    return (
        <div>
            <button onClick={paymentHandler} disabled={loading}>Pay</button>
            {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
        </div>
    );
};

export default RazorpayPayment;

