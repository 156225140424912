import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  lazy,
  Suspense,
} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import jwt_decode from "jwt-decode";
import io from "socket.io-client";
import Project from "./pages/Home/Project";
import "./App.css";
import Loader from "./components/Loader";
import { UsersContext, initialUserState } from "./context/usersContext";
import useReq from "./hook/useReq";
import PrivateRoutes from "./components/Protected";
import NonPrivateRoutes from "./components/NonProtected";
import { NavbarProvider } from "./context/NavbarContext";
import RootLayout from "./pages/Home/RootLayout";
import Dashboard from "./pages/Home/Dashboard";
import Projects from "./pages/Home/Projects";
import Help from "./pages/Home/Help";
import TermsOfUse from "./pages/TermsAndCondations/TermsOfUse";
import AboutUs from "./pages/TermsAndCondations/AboutUs";
import ResetPassword from "./pages/Auth/ResetPassword";
import SetNewPassword from "./pages/Auth/SetNewPassword";
import SignIn from "./pages/Auth/SignIn";
import SubscriptionPlan from "./pages/Auth/subscription/SubscriptionPlan";
import PaymentReceipt from "./pages/Auth/subscription/PaymentReceipt";
import TransactionStatus from "./pages/Auth/subscription/TransactionStatus";
import MyAccountWraper from "./pages/Home/MyAccountWraper";
import CancellationAndRefund from "./pages/TermsAndCondations/CancellationAndRefund";
import ContactUs from "./pages/TermsAndCondations/ContactUs";
import ShippingPolicy from "./pages/TermsAndCondations/ShippingPolicy";
import EnachMandate from "./pages/Auth/subscription/EnachMandate";
import RaiseTicket from "./pages/Home/components/RaiseTicketForm";
import RazorpayPayment from "./pages/Auth/subscription/razorpay/RazorpayPayment";
import EulaPolicy from "./pages/TermsAndCondations/EulaPolicy";
const Chatapp = lazy(() => import("./pages/Chat/chatapp"));
const UserManagement = lazy(() => import("./pages/Auth/UserManagement"));
const ProjectManagement = lazy(() => import("./pages/Auth/ProjectManagement"));
const GroupManagement = lazy(() => import("./pages/Auth/GroupManagement"));
const NotFound = lazy(() => import("./pages/Error/NotFound"));
const Forbidden = lazy(() => import("./pages/Error/Forbidden"));
const RegisterNow = lazy(() => import("./pages/Auth/RegisterNow"));
const userPrefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

function App() {
  const [appLoaded, setAppLoaded] = useState(false);
  const [startLoadProgress, setStartLoadProgress] = useState(false);

  const [showLoader, setShowLoader] = useState(true);
  const [checkedTokenIsAvailable, setCheckedTokenIsAvailable] = useState(false);

  const [isAlertActive, setIsAlertActive] = useState(false);
  const [alertTicks, setAlertTicks] = useState(null);
  const [alertText, setAlertText] = useState(null);

  const socket = useRef();

  const { loading, requestData, response, error } = useReq();
  const { requestData: requestDataL, response: responseL } = useReq();

  const [userDetails, setUserDetails] = useState(initialUserState);

  const { token } = userDetails;
  const ctx = useContext(UsersContext);

  // const isTokenExpired = () => {
  //   const pass = localStorage.getItem("token");
  //   if (!pass) return true;
  //   const decodedToken = jwt_decode(pass);

  //   return decodedToken.exp < Date.now() / 1000;
  // };

  // Effect to check token expiration on component mount
  // useEffect(() => {
  //   if (isTokenExpired()) {
  //     // Token has expired, log out or redirect to login page
  //     localStorage.removeItem("token");
  //     // You can also redirect to the login page here
  //   }
  // }, [token]);
  useEffect(() => {
    if (error !== null && error.message) {
      localStorage.removeItem("token");
      ctx.token = null;
    }
  }, [error]);

  useEffect(() => {
    document.body.classList.add("dark-theme");

    const token = localStorage.getItem("token");
    if (token) {
      setUserDetails((ud) => ({ ...ud, token }));
    } else {
      <redirect to="/" />;
      setCheckedTokenIsAvailable(true);
    }
  }, []);

  useEffect(() => {
    if (token) {
      requestData("GET", "/user/profile", null, token);
    }
  }, [token]);

  useEffect(() => {
    if (showLoader) {
      if (loading) {
        setStartLoadProgress(true);
      } else if (loading === false) {
        setAppLoaded(true);
        setShowLoader(false);
      }
    }
  }, [loading, showLoader]);

  useEffect(() => {
    if (response !== null) {
      setUserDetails((ud) => ({ ...ud, ...response }));
      if (!socket.current) {
        socket.current = io(process.env.REACT_APP_SOCKETIO);
      }

      if (socket.current) {
        socket.current?.emit("join", { token });

        //RECONNECTION LOGIC

        socket.current.io.on("reconnect", (attempt) => {
          socket.current?.emit("join", { token });
          if (sessionStorage.getItem("convId")) {
            socket.current?.emit("updatePage", {
              conversationId: sessionStorage.getItem("convId"),
            });
          }
        });
      }
    }

    return () => {
      if (socket.current) {
        socket.current.off();
      }
    };
  }, [response]);

  useEffect(() => {
    if (responseL !== null) {
      localStorage.removeItem("token");
      socket.current.off();
      socket.current = null;
      setUserDetails({ ...initialUserState });
    }
  }, [responseL]);

  const logout = () => {
    showLoadingOverlay(); // Show the blur overlay
    requestDataL("POST", "/auth/logout", null, token);

    setTimeout(() => {
      localStorage.clear();
      hideLoadingOverlay(); // Hide the blur overlay
    }, 1000);
  };

  function showLoadingOverlay() {
    const overlayElement = document.createElement("div");
    overlayElement.id = "loading-overlay";

    const loadingElement = document.createElement("div");
    loadingElement.id = "loading-animation";

    overlayElement.appendChild(loadingElement);
    document.body.appendChild(overlayElement);
  }

  function hideLoadingOverlay() {
    const overlayElement = document.getElementById("loading-overlay");
    if (overlayElement) {
      document.body.removeChild(overlayElement);
    }
  }

  const setAvatar = (av) => {
    setUserDetails({ ...userDetails, avatar: av });
  };

  const hideAlertHandler = () => {
    setIsAlertActive(false);
    setAlertText(null);
    setAlertTicks(null);
  };

  const showAlertHandler = (text) => {
    setIsAlertActive(true);
    setAlertText(text);
    setAlertTicks(Date.now());
  };

  const showAlert = (text) => {
    if (isAlertActive) {
      setTimeout(() => {
        setTimeout(() => {
          hideAlertHandler();
        }, 3000);
        showAlertHandler(text);
      }, 3000 - (Date.now() - alertTicks) + 500);
    } else {
      setTimeout(() => {
        hideAlertHandler();
      }, 3000);

      showAlertHandler(text);
    }
  };

  if (token && !appLoaded && showLoader)
    return <Loader done={startLoadProgress} />;

  // Check if the user is authenticated

  return (
    <NavbarProvider>
      <UsersContext.Provider
        value={{
          ...userDetails,
          logout,
          socket: socket.current,
          setAvatar,
          showAlert,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route exact path="/" element={<RootLayout />}>
                <Route
                  exact
                  path="/"
                  element={
                    <Dashboard userDetails={userDetails} logout={logout} />
                  }
                ></Route>
                <Route
                  exact
                  path="/projects"
                  element={
                    <Projects userDetails={userDetails} logout={logout} />
                  }
                ></Route>
                <Route
                  exact
                  path="/dashboard"
                  element={
                    <Dashboard userDetails={userDetails} logout={logout} />
                  }
                ></Route>

                <Route
                  exact
                  path="/help"
                  element={<Help userDetails={userDetails} logout={logout} />}
                ></Route>

                <Route
                  exact
                  path="/my-account"
                  element={<MyAccountWraper userDetails={userDetails} logout={logout} />}
                ></Route>
                <Route
                  exact
                  path="/raise-ticket"
                  element={
                    // <Suspense>
                      <RaiseTicket />
                    // </Suspense>
                  }
                ></Route>
                <Route
                  exact
                  path="/razor-pay"
                  element={
                    // <Suspense>
                      <RazorpayPayment />
                    // </Suspense>
                  }
                ></Route>
                <Route
                  exact
                  path="/project/:projectId"
                  element={
                    <Project userDetails={userDetails} logout={logout} />
                  }
                ></Route>
                <Route exact path="/chat/:chatId" element={<Chatapp />}></Route>
              </Route>
              <Route
                exact
                path="/usermanagement"
                element={
                  <Suspense>
                    <UserManagement userDetails={userDetails} logout={logout} />
                  </Suspense>
                }
              ></Route>
              <Route
                exact
                path="/projectManagement"
                element={
                  <Suspense>
                    <ProjectManagement
                      userDetails={userDetails}
                      logout={logout}
                    />
                  </Suspense>
                }
              ></Route>
              <Route
                exact
                path="/groupmanagement"
                element={
                  <Suspense>
                    <GroupManagement
                      userDetails={userDetails}
                      logout={logout}
                    />
                  </Suspense>
                }
              ></Route>
              {/* <Route exact component={NotFound} /> */}
            </Route>
            <Route
              exact
              path="/403"
              element={
                <Suspense>
                  <Forbidden />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/404"
              element={
                <Suspense>
                  <NotFound />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/termsofuse"
              element={
                <Suspense>
                  <TermsOfUse />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/refund-policy"
              element={
                <Suspense>
                  <CancellationAndRefund />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/contact-us"
              element={
                <Suspense>
                  <ContactUs />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/shipping-policy"
              element={
                <Suspense>
                  <ShippingPolicy />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/eula"
              element={
                <Suspense>
                  <EulaPolicy />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/subscription-plan"
              element={
                <Suspense>
                  <SubscriptionPlan />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/payment"
              element={
                <Suspense>
                  <PaymentReceipt />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/enach"
              element={
                <Suspense>
                  <EnachMandate />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/status"
              element={
                <Suspense>
                  <TransactionStatus />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/aboutus"
              element={
                <Suspense>
                  <AboutUs />
                </Suspense>
              }
            ></Route>
            <Route element={<NonPrivateRoutes />}>
              <Route
                exact
                path="/signup"
                element={
                  <RegisterNow
                    update={setUserDetails}
                    makeMeFalse={setShowLoader}
                  />
                }
              ></Route>
              <Route
                exact
                path="/signin"
                element={
                  <SignIn update={setUserDetails} makeMeFalse={setShowLoader} />
                }
              ></Route>
              <Route
                exact
                path="/reset-password"
                element={<ResetPassword />}
              ></Route>
              <Route
                exact
                path="/auth/new-password"
                element={<SetNewPassword />}
              ></Route>
            </Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </UsersContext.Provider>
    </NavbarProvider>
  );
}

// PrivateRoute component for routes that require authentication

export default App;
