import React from "react";
import Footer from "../Home/Footer";

function EulaPolicy(props) {
    return (
        <div
            style={{ height: "100vh" }}
            className="d-flex flex-column justify-content-between"
        >
            <div class="container d-flex flex-1 py-20 px-10 px-lg-20" >
                <div class="card">

                    <div class="terms-heading text-center ">
                        <h2 className="text-light">COLLABWORX END USER LICENSE AGREEMENT</h2>
                    </div>
                    <div class="card-body">
                        <p
                            style={{
                                paddingTop: "16pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "200%",
                                textAlign: "justify"
                            }}
                            className="fs-2"
                        >
                            <b>IMPORTANT</b> (Read Carefully): by using the Collabworx software platform, you
                            agree and acknowledge that you purchased the licensee from AHOU Ventures LLP
                            (hereinafter referred to as ‘<i>AHOU</i>’), and you agree to be bound by the
                            terms of this agreement. If you do not agree to the said terms then in that
                            case do not copy, access, or use the software platform.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p
                            style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "200%",
                                textAlign: "justify"
                            }}
                             className="fs-2"
                        >
                            Collabworx is an advanced collaboration and communication platform designed
                            specifically for the real estate industry, enabling builders, contractors,
                            vendors, and property owners to work together seamlessly on various
                            project/s. With a focus on document management and communication, the
                            Collabworx platform eliminates the need for downloading files, images,
                            documents etc., allowing users to view, share, and manage documents directly
                            within the system developed in Collabworx platform. By centralizing project
                            information and streamlining communication, the platform reduces
                            administrative overhead and helps users stay organized throughout the
                            project lifecycle. Key features such as group discussions, document sharing,
                            and project archiving empower Collabworx platform users to efficiently
                            manage real estate projects without the risk of data duplication or outdated
                            versions. The platform ensures that all stakeholders have access to the
                            latest project documents, simplifying collaboration between different
                            contractors and vendors.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>

                        <p
                            style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "200%",
                                textAlign: "justify"
                            }}
                             className="fs-2"
                        >
                            Collabworx platform is built to address the unique needs of the real estate
                            industry, providing users with a single point of access for all
                            project-related data. Users can easily track project progress, collaborate
                            with multiple parties, and access up-to-date documents without unnecessary
                            downloads or manual updates. By enabling real-time communication and secure
                            document handling, the platform minimizes miscommunication, keeps everyone
                            informed, and ensures project transparency. With flexible monthly pricing
                            per project, users only pay for what users need and required, and Collabworx
                            platform offers a hassle-free payment system with automated billing. Whether
                            user managing multiple contractors or handling complex projects, Collabworx
                            simplifies operations, reduces the risk of errors, and ensures that your
                            project runs smoothly from start to finish.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p
                            style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "200%",
                                textAlign: "justify"
                            }}
                             className="fs-2"
                        >
                            The following terms constitutes a binding agreement between You either an
                            individual, company or other legal entity, and its affiliates (“Licensee”)
                            and AHOU ("Licensor") in respect of Collabworx. This End User License
                            Agreement (“EULA”) is a legal agreement between the Licensee, and Licensor
                            in respect of this Software platform that you may obtain by creating account
                            using the credentials provided by Licensor. Collabworx platform supports
                            multi-device access but may limit concurrent sessions based on the license
                            agreement terms.
                        </p>

                        <p
                            style={{
                                paddingTop: "3pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "200%",
                                textAlign: "justify"
                            }}
                             className="fs-2"
                        >
                            Collabworx platform is compatible with modern browsers including Google
                            Chrome, Mozilla Firefox, Microsoft Edge, and Safari (latest versions) for
                            the web portal. For mobile access, Collabworx platform is supported on iOS
                            and Android devices meeting specified OS version requirements. Stable
                            internet connectivity is essential for optimal performance. A licensor may
                            periodically release updates, bug fixes, and feature enhancements. These
                            updates are typically deployed automatically to ensure seamless
                            functionality, and Licensees may be notified in advance of any downtime
                            required for significant updates or maintenance. Collabworx platform employs
                            industry-standard encryption protocols for data transmission and storage.
                            Platform access is controlled through secure authentication methods,
                            including password protection and optional multi-factor authentication.
                            Users are responsible for maintaining the confidentiality of their
                            credentials or any other relevant information under this agreement.
                        </p>
                        <p style={{ paddingTop: "16pt", textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>
                        <p
                            className="s3 fs-2"
                            style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "200%",
                                textAlign: "justify"
                            }}
                             
                        >
                            <a href="https://collabworx.in/" className="s5" target="_blank">
                                For the purpose of this agreement the Licensor has made a website which is
                                available at{" "}
                            </a>
                            https://collabworx.in/{" "}
                            <span style={{ color: "#000" }}>
                                and on Play Store and App Store for the
                            </span>
                        </p>
                        <p
                            style={{
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "200%",
                                textAlign: "left"
                            }}
                            className="fs-2"
                        >
                            purpose of providing access to Collabworx pursuant to the Licensee from
                            Licensor i.e., AHOU.
                        </p>
                        <p
                            style={{
                                paddingTop: "3pt",
                                paddingLeft: "5pt",
                                textIndent: "0pt",
                                lineHeight: "200%",
                                textAlign: "justify"
                            }}
                            className="fs-2"
                        >
                            By accessing/downloading/installing/loading/operating/executing this
                            Platform or a part thereof in accordance with the user Manual of Collabworx,
                            you agree to bound by the terms and condition of this EULA.
                        </p>
                        <p style={{ paddingTop: "16pt", textIndent: "0pt", textAlign: "left" }}>
                            <br />
                        </p>

                        <ol id="l1">
                            <li data-list-text={1}>
                                <h2
                                    style={{ paddingLeft: "19pt", textIndent: "-14pt", textAlign: "left" }}
                                >
                                    DEFINITIONS:
                                </h2>
                                <ol id="l2">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            //  className="fs-2"
                                            
                                        >
                                            “Affiliates”{" "}
                                            <span className="p">
                                                means an entity controlled by, under common control with, or
                                                controlling such party, where control is denoted as having fifty
                                                percent (50%) or more of the voting power (or equivalent) of the
                                                applicable legal entity. Subject to the terms and conditions of
                                                this Agreement, Affiliates may use the license granted hereunder.
                                                All references to Licensor shall be deemed to be references to
                                                Licensor and its Affiliates and all references to Licensee shall
                                                be deemed to be references to Licensee’s company or other legal
                                                entity and its Affiliate(s).
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            “Computer”
                                            <span className="p">
                                                means the hardware, if the hardware is a single computer system,
                                                whether physical or virtual, or means the computer system with
                                                which the hardware operates, if the hardware is a computer system
                                                component.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">
                                            “<b>Collabworx Platform” or “Collabworx</b>” refers to the online
                                            collaboration tool provided by AHOU designed for real estate
                                            professionals to manage projects, documents, and communications
                                            between builders, contractors, vendors, and property owners.
                                            </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="d.">
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">
                                                
                                            “<b>Software Platform</b>” means the object code version of the
                                            product, together with the user manual, and all third-party software
                                            that Licensor may have purchased or licensed from third parties and
                                            delivered to Licensee as part of the Software, as well as any
                                            Updates provided by Licensor to Licensee pursuant to this Agreement.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="e.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">
                                                
                                            “<b>Licensee</b>” refers to the individual or legal entity that has
                                            entered into this Agreement with the Licensor to obtain a license to
                                            use the Collabworx platform. The Licensee is responsible for all
                                            subscription payments and compliance with the terms of this
                                            Agreement. The Licensee is entitled to create and authorize Users
                                            within the organization to access Collabworx platform on any number
                                            of computers or devices owned by the Licensee.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="f.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">
                                                
                                            “<b>User</b>” refers to any individual authorized by the Licensee to
                                            access and interact with the Collabworx Platform within the scope of
                                            the Licensee’s rights under this Agreement. Users may include
                                            employees, contractors, vendors, real estate developers, project
                                            owners or any other individuals designated by the Licensee.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="g.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Project</b>” refers to any real estate development,
                                            construction, or renovation activity managed through the Collabworx
                                            Platform, where stakeholders collaborate, share documents, and
                                            communicate to achieve project goals.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="h.">
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Document Management</b>” refers to Collabworx Platform's
                                            functionality allowing Users to upload, view, share, and organize
                                            documents related to specific projects, ensuring secure and
                                            centralized access without the need for downloads.
                                             </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="i.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Subscription Fees</b>” refers to the monthly charges payable by
                                            Users for using the Collabworx Platform for each project. These fees
                                            are automatically debited via the payment gateways integrated into
                                            the Collabworx Platform.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="j.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Auto-Renewal</b>” refers to the automatic continuation of the
                                            User's subscription to the Collabworx Platform unless the User opts
                                            for cancellation before the billing cycle renews.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="k.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Archive</b>” refers to the Collabworx Platform feature allowing
                                            Users to close a project once completed, locking its access while
                                            preserving the data for future reference, unless permanently deleted
                                            as per the retention policy.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="l.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Retention Policy</b>” refers to the rule whereby the project
                                            data will be stored for up to seven years from the date of
                                            inactivity or non-payment, after which it will be permanently
                                            deleted.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="m.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Login Access</b>” refers to the User's ability to access the
                                            Collabworx Platform, without restriction on the number of logins,
                                            for the purpose of managing and collaborating on projects.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="n.">
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Auto-Debit</b>” refers to the automatic deduction of
                                            subscription fees from the User's payment method on a recurring
                                            monthly basis for ongoing projects on the Collabworx Platform.
                                             </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="o.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Notification</b>” refers to the alerts sent to the User by the
                                            Collabworx Platform to inform them of upcoming payments, project
                                            statuses, or changes in subscription terms, including reminders
                                            Three days before the due date of payments.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="p.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Project Lock</b>” refers to the restriction of access to a
                                            project and its associated documents due to non-payment or
                                            non-renewal of the subscription, preventing further collaboration
                                            until payments are made.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="q.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <sxpan className="p">

                                            “<b>Payment Gateway</b>” refers to any third-party service providers
                                            that process subscription payments on behalf of the Collabworx.
                                             </sxpan>
                                        </h4>
                                    </li>
                                    <li data-list-text="r.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Cancellation</b>” refers to the User's decision to terminate the
                                            subscription, resulting in the archiving of the project and eventual
                                            deletion of the data as per the retention policy.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="s.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Derivative Works</b>” means a revision, enhancement,
                                            modification, translation, abridgment, condensation, or expansion of
                                            software or any other form in which such software may be recast,
                                            transferred, or adapted, which, if used without the consent of
                                            Licensor, would constitute a copyright infringement.
                                             </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="t.">
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>User Manual</b>” means the official explanatory materials in
                                            printed, electronic or online form provided by Licensor to
                                            Licensee/User on the use of the software. For the avoidance of
                                            doubt, any installation guide or end user documentation not prepared
                                            or provided by Licensor, any online community site, unofficial
                                            documentation, videos, white papers, or feedback does not constitute
                                            User Manual.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="u.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Fees</b>” means the fees for the Software license (including any
                                            renewal or extension thereof), software Update Service, or any other
                                            plan/s purchased, or service/s rendered under this Agreement.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="v.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Software Update Service</b>” means the service for the
                                            correction of errors and/or support of the software and the issuance
                                            of any Updates.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="w.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Updates</b>” means all subsequent releases and versions of the
                                            software that Licensor makes generally available to its Licensee’s
                                            as part of purchased software update service and which are
                                            separately priced or marketed by Licensor.
                                             </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="x.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Proprietary Rights</b>” means all rights of the Licensor in the
                                            software including but not limited to Trademarks, Copyrights,
                                            Patents, designs, trade Secrets, inventions, utility models, works
                                            of authorship, data, designs, databases, topography and mask works,
                                            ideas, know-how, source codes, trade secrets, confidential
                                            information, and any other type of intellectual property and
                                            proprietary rights; in each case whether registered
                                             </span>
                                        </h4>
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            or unregistered and including all applications, registrations,
                                            renewals and the like including business IP and IT.
                                             </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="y.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                             <span className="p">

                                            “<b>Confidential Information</b>” means all information and data
                                            including but not limited to, not generally known in the public
                                            domain with regards to the software platform and technical
                                            information, source code and object code, platform design, firmware,
                                            I/C coding, hardware, manuals, devices, samples, models, processes,
                                            specifications, databases, topography and mask works, ideas,
                                            know-how, source codes, trade secrets.
                                             </span>
                                        </h4>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={2}>
                                <h2
                                    style={{ paddingLeft: "19pt", textIndent: "-14pt", textAlign: "left" }}
                                >
                                    GRANT OF LICENSE:
                                </h2>
                                <ol id="l3">
                                    <li data-list-text="a.">
                                        <p
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            The Licensor hereby grants to the Licensee and/or User,
                                            non-exclusive, non-transferable, non-assignable, restricted license
                                            during the term set forth in this Agreement, to access in accordance
                                            with the relevant user manual for Licensee’s own internal business
                                            purposes only.
                                        </p>
                                    </li>
                                    <li data-list-text="b.">
                                        <p
                                            style={{
                                                paddingLeft: "40pt",
                                                textIndent: "-17pt",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            A license is permitted to install the Collabworx software under its
                                            control.
                                        </p>
                                        <p
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            The licensee and/or User is/are responsible for ensuring the device
                                            meets the minimum requirements as specified in the Collabworx user
                                            manual.
                                        </p>
                                    </li>
                                    <li data-list-text="c.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            The Licensee is entitled to use the said Collabworx platform on any
                                            number of computers by creating any number of users. The Licensed
                                            Software may only be accessed by Users on hardware belonging to
                                            Licensee, and may not be accessed by any other means, including
                                            without limitation via a
                                        </p>
                                        <p
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            network unless Licensee has been granted a network license pursuant
                                            to the terms and conditions of this Agreement.
                                        </p>

                                    </li>
                                    <li data-list-text="d.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            Licensee acknowledges that the Collabworx platform, and the user
                                            manual is proprietary to Licensor and may not be distributed to any
                                            third parties by the Licensee. Licensee is not granted rights to
                                            Updates unless Licensee has purchased support services. The license
                                            granted herein is subject to the specific restrictions and
                                            limitations set forth herein, the terms of the open- source licenses
                                            governing the components included in the Collabworx platform, and/or
                                            any additional licensing restrictions and limitations specified in
                                            the Documentation, or by notification and/or policy change posted at
                                            Licensor’s website.
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={3}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    RESTRICTIONS OF LICENSE:{" "}
                                    <span className="p">
                                        Subject to the terms set forth and to the terms of the open-source
                                        licenses governing the open-source components of the Collabworx
                                        platform, the Licensee and/or User shall not:
                                    </span>
                                </h2>
                                <ol id="l4">
                                    <li data-list-text="a.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            Use, reproduce, or display, other than those specifically described
                                            in this Agreement are granted to Licensee. A licensee may not modify
                                            or make works derivative of the Collabworx platform or make
                                            compilations or collective works that include the said Collabworx
                                            platform.
                                        </p>
                                    </li>
                                    <li data-list-text="b.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            Edit, alter, modify, adapt, translate, or otherwise change the whole
                                            or any part of the Software nor permit the whole or any part of the
                                            Software to be
                                        </p>
                                        <p
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            combined with or become incorporated in any other software platform,
                                            nor decompile, disassemble or reverse engineer the Collabworx
                                            platform or attempt to do any such things.
                                        </p>


                                    </li>
                                    <li data-list-text="c.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            Reproduce, copy, distribute, resell, or otherwise use the Collabworx
                                            platform for any commercial purpose.
                                        </p>
                                    </li>
                                    <li data-list-text="d.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            Allow any third party to use the Collabworx platform on behalf of or
                                            for the benefit of any third party.
                                        </p>
                                    </li>
                                    <li data-list-text="e.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            Use the Collabworx platform in any way which breaches any applicable
                                            local, national, or international law.
                                        </p>
                                    </li>
                                    <li data-list-text="f.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            Reject, avoid, elude, remove, deactivate, or evade, in any way, any
                                            protection mechanism of the Collabworx platform, including without
                                            limitation any mechanism used to restrict or control Collabworx
                                            platform functions.
                                        </p>
                                    </li>
                                    <li data-list-text="g.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            Disclose to any third party any benchmarking or comparative study
                                            involving the Collabworx platform.
                                        </p>
                                    </li>
                                    <li data-list-text="h.">
                                        <p
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                            className="fs-4"
                                        >
                                            A licensee may not delete, remove, hide, move, or alter any
                                            trademark, logo, icon, image, or text that represents the Licensor’s
                                            name, any derivation thereof, or any icon, image, or text that is
                                            likely to be confused with the same. All representations of the
                                            Licensor’s name, logo or other mark of Licensor or any of its
                                            Affiliates’ names or marks must remain as
                                        </p>
                                        <p
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "left"
                                            }}
                                            className="fs-4"
                                        >
                                            originally distributed regardless of the presence or absence of a
                                            trademark, copyright, or other intellectual property symbol or
                                            notice.
                                        </p>
                                        <p
                                            style={{ paddingTop: "16pt", textIndent: "0pt", textAlign: "left" }}
                                        >
                                            <br />
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={4}>
                                <h2
                                    style={{ paddingLeft: "19pt", textIndent: "-14pt", textAlign: "left" }}
                                >
                                    LICENSEE FEE:
                                </h2>

                                <ol id="l5">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Subscription Fees:{" "}
                                            <span className="p">
                                                Collabworx Platform operates on a monthly subscription model,
                                                where a fee is charged for each project managed through Collabworx
                                                Platform. The applicable fees are determined based on the specific
                                                plan/s chosen by the User at the time of project creation. Fees
                                                are subject to change, and Users will be notified in advance of
                                                any changes to the subscription rates.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Payment Method:{" "}
                                            <span className="p">
                                                Subscription fees are automatically debited from the Licensee’s
                                                and/or User’s registered payment method on a recurring monthly
                                                basis through Collabworx Platform’s integrated payment gateways.
                                                Collabworx Platform will notify Licensee and/or User of upcoming
                                                charges Three days prior to the payment due date. However, it is
                                                the duty of the Licensee and/or User to pay respective license
                                                fees within the time. Collabworx Platform shall not be responsible
                                                for closure of services, if the user does not receive
                                                notification.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Free Registration:{" "}
                                            <span className="p">
                                                Licensee and/or Users are not required to pay any registration
                                                fees to create an account on Collabworx Platform. However,
                                            </span>
                                        </h4>
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            access to project management features is contingent upon payment of
                                            concerned fees.
                                            </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="d.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Consequences for Non-Payment:{" "}
                                            <span className="p">
                                                If Licensee and/or User fails to make timely payment of the
                                                subscription fees, the associated project will be locked, and no
                                                access to project data or files will be granted until all
                                                outstanding payments are settled. No security deposit is required
                                                for subscription services, and the project will remain locked
                                                until payments are completed.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="e.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Cancellation and Refund:{" "}
                                            <span className="p">
                                                The Licensee and/or User may cancel the subscription at any time.
                                                Upon cancellation, the User’s concerned project/s will be archived
                                                and accessible only for viewing purposes. No refund will be
                                                provided for partial months or unused services during the
                                                subscription period.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="f.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Renewal:
                                            <span className="p">
                                                Subscription to Collabworx Platform is automatically renewed on a
                                                monthly basis unless canceled by the Licensee and/or User.
                                                Collabworx Platform will collect the subscription fees via
                                                auto-debit at the start of each billing cycle.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="g.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Taxes:
                                            <span className="p">
                                                The Licensee and/or User is responsible for all applicable taxes,
                                                including but not limited to GST, or any other government-imposed
                                                charges related to the subscription fees.
                                            </span>
                                        </h4>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={5}>
                                <h4
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        textAlign: "left"
                                    }}
                                >
                                    TERM AND TERMINATION:
                                </h4>
                                <ol id="l6">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            This Agreement and the licenses granted hereunder shall remain in
                                            effect until terminated by either party.
                                            </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            The Licensee and/or User may terminate this Agreement at any time by
                                            canceling their subscription and ceasing to use Collabworx Platform.
                                            Upon termination by the Licensee and/or User all active project/s
                                            will be archived, and data will be retained in accordance with the
                                            platform’s data retention policy until permanently deleted. No
                                            refunds will be provided for the unused portion of the subscription
                                            period. Auto-renewal will be discontinued, and no further payments
                                            will be charged. Licensor may terminate this Agreement, and the
                                            licenses granted hereunder, upon written notice for any material
                                            breach of this Agreement that Licensee fails to cure within 15
                                            (Fifteen) days following written notice specifying such breach.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Licensee may terminate this Agreement, and the licenses granted
                                            hereunder upon written notice for any material breach of this
                                            Agreement that Licensor fails to cure within 15 (Fifteen) days
                                            following written notice specifying such breach. Clauses that
                                            intents to survive shall survive termination of this agreement.
                                        </h4>
                                    </li>
                                    <li data-list-text="d.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Licensor may terminate this Agreement and restrict access to
                                            Collabworx Platform if the Licensee and/or User fails to comply with
                                            any terms herein, engages in misuse, or fails to pay fees. Upon
                                            termination, Licensee and/or
                                            </span>
                                        </h4>
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "left"
                                            }}
                                        >
                                            <span className="p">
                                                
                                            User access to Collabworx Platform will cease, and associated data
                                            will be retained for a period of 07 (Seven) years before deletion.
                                            </span>
                                        </h4>
                                        <p
                                            style={{ paddingTop: "16pt", textIndent: "0pt", textAlign: "left" }}
                                        >
                                            <br />
                                        </p>

                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={6}>
                                <h4
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    DISCLAIMER:{" "}
                                    <span className="p">
                                        Collabworx Platform provides services on an “as is” only for the
                                        intended purpose basis without warranty of any kind, either express or
                                        implied. The licensor does not warrant that the use of Collabworx
                                        Platform will meet Licensee and/or User’s all requirements or that the
                                        operation thereof will be fail safe, uninterrupted, error free, or
                                        that the software will protect against all possible threats. The
                                        licensor disclaims all other warranties, express or implied, including
                                        but not limited to any warranties of satisfactory quality, fitness for
                                        a particular purpose, non-infringement, non-interference, and accuracy
                                        of informational content.
                                    </span>
                                </h4>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={7}>
                                <h4
                                    style={{ paddingLeft: "19pt", textIndent: "-14pt", textAlign: "left" }}
                                >
                                    INTELLECTUAL PROPERTY:
                                </h4>
                                <ol id="l7">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Ownership:{" "}
                                            <span className="p">
                                                The Intellectual Property rights in respect of the Collabworx
                                                platform shall always vest with the Licensor. Collabworx Platform,
                                                including any copies made by or for Licensee and/or User, in whole
                                                or in part, are the sole property of the Licensor. All
                                                intellectual property rights in Collabworx platform belong
                                                exclusively to the Licensor. The Licensor shall retain all rights,
                                                titles, copyright, trademarks, designs and other intellectual
                                                property rights in Collabworx platform and all modifications,
                                            </span>
                                        </h4>

                                        <p
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            enhancements, or other works derivative of Collabworx platform.
                                            Licensee and/or User recognize that methodologies, techniques,
                                            expressions, ideas and concepts contained in or expressed within
                                            Collabworx platform are proprietary information and may be trade
                                            secrets of the Licensor. The data generated by the Licensee and/or
                                            User due to the usage of the Collabworx platform shall exclusively
                                            remain the property of the Licensee and/or User respectively.
                                        </p>
                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Open-Source Components:{" "}
                                            <span className="p">
                                                Collabworx platform includes certain third- party software as set
                                                forth in the user manual and may be used only in accordance with
                                                the licenses set forth therein, which prevail over the terms of
                                                this Agreement with respect to such third-party software platform.
                                                Source/Object code for these components is available upon written
                                                request to Licensor. With respect to the Collabworx platform,
                                                third-party components are integrated by Licensor for and on
                                                behalf of Licensee and/or User in accordance with this Agreement.
                                                Therefore, any third-party software platform delivered to Licensee
                                                and/or User pursuant to this Agreement, including any third-party
                                                plug-in that may be provided with the Collabworx platform, is
                                                included for use at Licensee and/or User’s option, solely in
                                                accordance with the corresponding third-party software license(s).
                                                Licensor shall have no liability for Licensee and/or User’s use of
                                                any third-party software.
                                            </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Use of Collabworx platform
                                            <span className="p">
                                                : Licensee and/or User is/are granted only a limited,
                                                non-transferable, revocable license for using the Collabworx
                                                platform, without any rights to the underlying IP. Further the
                                                Licensee and/or User expressly undertakes that the Licensee and/or
                                                User cannot copy, modify, distribute, or create derivative works
                                                from Collabworx platform, nor access or attempt to access the
                                                source/object code.
                                            </span>
                                        </h4>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={8}>
                                <h2
                                    style={{ paddingLeft: "19pt", textIndent: "-14pt", textAlign: "left" }}
                                >
                                    CONFIDENTIALITY:
                                </h2>
                                <ol id="l8">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Licensee and/or User acknowledges and agree that the Collabworx
                                            platform incorporates confidential and proprietary information
                                            developed or acquired by Licensor including, but not limited to,
                                            technical and non- technical data, formulas, patterns, compilations,
                                            devices, methods, techniques, drawings, and processes related to the
                                            Collabworx platform, which constitutes the valuable intellectual
                                            property of Licensor and its suppliers. Licensee and/or User will
                                            comply with all laws and regulations that apply to the use,
                                            transmission, storage, disclosure, or destruction of Confidential
                                            Information.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            In the course of performance of obligations under this Agreement,
                                            Licensee and/or User may receive, be exposed to, or acquire
                                            Confidential Information of the Licensor. The Licensee and/or User
                                            shall protect the Confidential Information of the Licensor by using
                                            at least the same degree
                                            </span>
                                        </h4>

                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            of care it uses to protect its own Confidential Information of
                                            similar importance or sensitivity, but no less than a reasonable
                                            degree of care, to prevent the unauthorized use, dissemination, or
                                            application of such Confidential Information. The Licensee and/or
                                            User shall not, except with the written consent of the Licensor,
                                            disclose the Confidential Information of the Licensor, or use it,
                                            except to its employees, contractors, and agents who have a need to
                                            know for purposes of this Agreement. The Licensee and/or User shall
                                            cause its employees, contractors, and agents receiving the
                                            Licensor’s Confidential Information to provide the same or greater
                                            protection for such Confidential Information.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            If the Licensee and/or User is required to disclose Confidential
                                            Information of the Licensor pursuant to a court order or government
                                            authority, the Licensee and/or User shall provide a reasonable
                                            notice where feasible to the Licensor prior to such disclosure and
                                            shall cooperate with the Licensor to obtain protection from such
                                            disclosure.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="d.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            The Licensee and/or User shall not remove, obscure, or deface any
                                            proprietary or confidentiality legends relating to the Licensor’s
                                            rights, on or from any electronic or tangible embodiment of any
                                            Confidential Information, without the Licensor’s prior written
                                            consent.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="e.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Licensee and/or User agrees and acknowledges that money damages may
                                            not be a sufficient remedy for any breach of the confidentiality
                                            provisions
                                            </span>
                                        </h4>

                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            of this Agreement and that Licensor may be entitled to equitable
                                            relief, including injunction and specific performance, as a remedy
                                            for any such breach against the Licensee and/or User. Such remedies
                                            may not be deemed exclusive remedies for a breach of these
                                            provisions but may be deemed in addition to all other remedies
                                            available at law or in equity.
                                            </span>
                                        </h4>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={9}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    DATA USAGE AND ANALYTICS:{" "}
                                    <span className="p fs-4">
                                        Licensor may analyze non-personal, aggregated data generated by the
                                        Licensee and/or User’s activity on Collabworx platform to improve the
                                        Collabworx platform’s performance, security, and features. User data
                                        will be handled in compliance with the General Data Protection
                                        Regulation (GDPR) and other applicable data protection laws.
                                        Identifiable personal information will not be shared with any third
                                        parties without Licensee’s consent. Any use of Licensee and/or User’s
                                        data for marketing purposes will only be made with the explicit
                                        consent of the Licensee.
                                    </span>
                                </h2>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={10}>
                                <h2
                                    style={{ paddingLeft: "26pt", textIndent: "-21pt", textAlign: "left" }}
                                >
                                    DATA PROTECTION AND PRIVACY COMPLIANCE:
                                    <span className="p fs-4">The Licensor</span>
                                </h2>
                                <h4
                                    style={{
                                        paddingTop: "16pt",
                                        paddingLeft: "19pt",
                                        textIndent: "0pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    <span className="p">

                                    is committed to protecting the privacy and confidentiality of the User’s
                                    data in accordance with applicable data protection laws, including the
                                    Information Technology Act, 2000 (as amended), and, where applicable,
                                    the General Data Protection Regulation (GDPR). The Licensor agrees to
                                    process personal data
                                    </span>
                                </h4>
                                <h4
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "19pt",
                                        textIndent: "0pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    <span className="p">

                                    only for purposes specified in this Agreement and to implement adequate
                                    technical and organizational measures to safeguard such data against
                                    unauthorized access, alteration, disclosure, or destruction.
                                    </span>
                                </h4>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>

                            </li>
                            <li data-list-text={11}>
                                <h2
                                    style={{ paddingLeft: "26pt", textIndent: "-21pt", textAlign: "left" }}
                                >
                                    DATA PROCESSING AND SECURITY MEASURES:
                                    <span className="p">The Licensor</span>
                                </h2>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <h4
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "0pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    <span className="p">

                                    processes User data in compliance with the Information Technology Act
                                    and, where applicable, GDPR, solely for purposes related to the
                                    operation and enhancement of Collabworx platform. The Licensor utilizes
                                    industry-standard encryption and other security protocols to protect
                                    User data. The Licensor further commits to conducting regular security
                                    audits and vulnerability assessments to ensure ongoing compliance with
                                    these standards.
                                    </span>
                                </h4>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={12}>
                                <h4
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    DATA RETENTION AND DELETION:{" "}
                                    <span className="p">
                                        Licensee and/or User data will be retained on the Platform for the
                                        duration of the subscription and will be deleted no later than 07
                                        (Seven) years after the termination of services, unless otherwise
                                        required by law. Licensee and/or User may also request deletion of
                                        their data in accordance with their rights under the GDPR or IT Act.
                                        Upon receipt of such a request, the Licensor will delete all personal
                                        data unless it is necessary to retain it for legal or regulatory
                                        reasons.
                                    </span>
                                </h4>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={13}>
                                <h2
                                    style={{ paddingLeft: "26pt", textIndent: "-21pt", textAlign: "left" }}
                                >
                                    COLLABWORX PLATFORM UPDATES AND MAINTENANCE:
                                </h2>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <h4
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "0pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    <span className="p">

                                    Collabworx platform may periodically send updates to improve
                                    functionality or add new features. During scheduled maintenance,
                                    Collabworx platform may
                                    </span>
                                </h4>
                                <h4
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "19pt",
                                        textIndent: "0pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    <span className="p">

                                    be temporarily unavailable; advance notice will be provided where
                                    possible. Licensee and/or Users are responsible for ensuring the
                                    security of their login information and maintaining compatibility with
                                    any new updates.
                                    </span>
                                </h4>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>

                            </li>
                            <li data-list-text={14}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    PROHIBITED ACTIVITIES:{" "}
                                    <span className="p">
                                        The Licensee and/or User shall not engage in the following activities:
                                    </span>
                                </h2>
                                <ol id="l9">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingLeft: "59pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Unauthorized attempts to access or modify any part of Collabworx
                                            platform.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingLeft: "59pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Use of Collabworx platform for unlawful or prohibited activities,
                                            including infringement on the rights of others or uploading harmful
                                            content.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingLeft: "59pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Sublicensing, renting, leasing, or selling access to Collabworx
                                            platform, or allowing others to access it in a way that violates
                                            this Agreement.
                                            </span>
                                        </h4>
                                        <p
                                            style={{ paddingTop: "15pt", textIndent: "0pt", textAlign: "left" }}
                                        >
                                            <br />
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={15}>
                                <h2
                                    style={{ paddingLeft: "26pt", textIndent: "-21pt", textAlign: "left" }}
                                >
                                    INDEMNIFICATION:
                                </h2>
                                <ol id="l10">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Licensor shall indemnify and hold harmless Licensee and its
                                            officers, employees, agents, affiliates, and representatives and
                                            defend any action brought against same with respect to any
                                            third-party claim, demand or cause of action, including reasonable
                                            attorney’s fees, to the extent that it is based upon a claim that
                                            Collabworx infringes or violates any patents, copyrights, trade
                                            secrets, or other proprietary rights of a third-party.
                                            </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Licensee and/or User may, at its own expense, assist in such defense
                                            if it so desires, provided that Licensor shall control such defense
                                            and all negotiations relating to the settlement of any such claim.
                                            Licensee and/or User shall promptly provide Licensor with written
                                            notice of any claim which Licensee believes falls within the scope
                                            of this indemnification provision of the Agreement. In the event
                                            that the use of Collabworx platform or any portion thereof is held
                                            to constitute an infringement, Licensor may, at its sole option and
                                            expense, (i) modify the infringing Software platform so that it is
                                            non-infringing, (ii) procure for Licensee the right to continue to
                                            use the infringing Collabworx platform, or (iii) replace said
                                            Collabworx platform with suitable, non-infringing Collabworx
                                            platform.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Notwithstanding the foregoing, Licensor will have no obligation for
                                            any claims to the extent such claims result from (i) modifications
                                            or alterations of Collabworx platform made by or for Licensee and/or
                                            User or any other party that were not provided by Licensor or
                                            authorized by Licensor in writing; (ii) use outside the scope of the
                                            license granted hereunder, (iii) use of a superseded or previous
                                            version of Collabworx platform if infringement would have been
                                            avoided by the use of a newer version which Licensor made available
                                            to Licensee and/or User, or (iv) use of Collabworx platform in
                                            combination with any other software, hardware or products not
                                            supplied
                                            </span>
                                        </h4>
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            by Licensor. This indemnity obligation is subject to the limitation
                                            of liability and does not apply to any open-source components used
                                            in Collabworx platform.
                                            </span>
                                        </h4>
                                        <p
                                            style={{ paddingTop: "16pt", textIndent: "0pt", textAlign: "left" }}
                                        >
                                            <br />
                                        </p>

                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={16}>
                                <h2
                                    style={{ paddingLeft: "26pt", textIndent: "-21pt", textAlign: "left" }}
                                >
                                    LIMITATION OF LIABILITY:
                                </h2>
                                <ol id="l11">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            For any license purchased for use on a computer or device or portal
                                            or the like, the Licensor’s liability is limited to the refund of
                                            the License fee of Collabworx platform.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            {" "}
                                            <span className="p">

                                            In no event to the fullest extent permissible under applicable laws
                                            of India, the Licensors shall be liable for loss or damage suffered
                                            in connection with the use of Collabworx platform or any related
                                            third-party open-source components. This also includes tort,
                                            contract, any indirect, special, incidental, punitive, or
                                            consequential damages, costs, losses, or expense, (including but not
                                            limited to lost profits, loss or interruption of use, loss of data,
                                            work stoppage, damage to networks, equipment, or hardware,).
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Regardless of whether the claim for such damages is based in
                                            contract, tort, or any other legal theory, in no event shall
                                            Licensor’s aggregate liability to Licensee for direct damages exceed
                                            the original licensee fee of the Software Platform, Software Update
                                            Service or other amounts paid by Licensee, even if Licensor has been
                                            advised of such damages. The
                                            </span>
                                        </h4>
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            foregoing limitations shall apply to the maximum extent permitted by
                                            applicable law.
                                            </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="d.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Notwithstanding the aforementioned limitations of liability, your
                                            sole remedy in the event of a dispute with Licensor is to cease to
                                            use the product; and if applicable, seek damages for your losses.
                                            </span>
                                        </h4>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={17}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    FORCE MAJEURE: 
                                    <span className="p fs-4">
                                         
                                         Licensor will not be held liable for any default or delay in the
                                        performance of its obligations under this Agreement if such default or
                                        delay is caused, directly or indirectly, by any of the following:
                                        fire, flood, earthquake or acts of God or act of terror; riots, civil
                                        disorders, rebellions in India; strikes, lockouts, epidemic, pandemic
                                        etc. Licensor’s performance is prevented, restricted, or interfered
                                        with by reason of a Force Majeure condition shall be excused from such
                                        performance to the extent of such Force Majeure condition so long as
                                        such Licensor provides the Licensee with prompt written notice
                                        describing the Force Majeure condition and takes all reasonable steps
                                        to avoid or remove such causes of non-performance and immediately
                                        continues performance whenever and to the extent such causes are
                                        removed. If, due to a Force Majeure condition, the scheduled time of
                                        delivery or performance is or will be delayed for more than one (1)
                                        month, the parties shall mutually decide on a course of action to be
                                        undertaken.
                                    </span>
                                </h2>
                            </li>
                            <li data-list-text={18}>
                                <h2
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "26pt",
                                        textIndent: "-21pt",
                                        textAlign: "left"
                                    }}
                                >
                                    GOVERNING LAW, DISPUTE RESOLUTION AND JURISDICTION:
                                </h2>

                                <ol id="l12">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            This Agreement shall be governed by and construed under the laws of
                                            India. Any dispute arising out of or in connection with this
                                            Agreement, including any question regarding its existence, validity
                                            or termination, shall be first referred to and resolved by mediation
                                            between the Parties. If the Parties fail to settle the dispute by
                                            mediation within thirty (30) days from the date of beginning of
                                            mediation, the dispute then be resolved by referring the dispute to
                                            arbitration in Pune, India in accordance with the Indian Arbitration
                                            Act 1996. The arbitration tribunal shall consist of 1 (one)
                                            arbitrator jointly appointed by the Parties. If the Parties fail to
                                            agree on appointment of such arbitrator, the sole arbitrator shall
                                            be appointed in accordance with the Indian Arbitration Act 1996.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Subject to the above clause, the courts of Pune, India shall have
                                            exclusive jurisdiction on the matters arising from or in connection
                                            with this Agreement.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingLeft: "40pt",
                                                textIndent: "-17pt",
                                                lineHeight: "16pt",
                                                textAlign: "left"
                                            }}
                                        >
                                            <span className="p">

                                            The language of the arbitration shall be English.
                                            </span>
                                        </h4>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                    </li>
                                    <li data-list-text="d.">
                                        <h4
                                            style={{
                                                paddingLeft: "40pt",
                                                textIndent: "-17pt",
                                                textAlign: "left"
                                            }}
                                        >
                                            <span className="p">

                                            The arbitrator shall be entitled to award costs of the arbitration.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="e.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            <span className="p">

                                            Subject to the aforesaid, each Party to any arbitration shall bear
                                            its own expense in relation thereto, including but not limited to
                                            such Party’s attorneys’ fees and the expenses and fees of the
                                            arbitrator shall be borne
                                            </span>
                                        </h4>
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "left"
                                            }}
                                        >
                                            <span className="p">

                                            equally by the Parties to the dispute. The arbitrator shall reach
                                            and render a reasoned decision in writing.
                                            </span>
                                        </h4>
                                        <p
                                            style={{ paddingTop: "16pt", textIndent: "0pt", textAlign: "left" }}
                                        >
                                            <br />
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={19}>
                                <h2
                                    style={{ paddingLeft: "26pt", textIndent: "-21pt", textAlign: "left" }}
                                >
                                    PRIVACY POLICY:
                                </h2>

                                <ol id="l13">
                                    <li data-list-text="a.">
                                        <h4
                                            style={{
                                                paddingTop: "16pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Information Collected:{" "}
                                            <span className="p">
                                                The Licensee is required to provide information to create login to
                                                access Collabworx platform. Collabworx platform may collect
                                                information when the Licensee registers. While registering, the
                                                Collabworx platform asks for information of the Licensee and/or
                                                User or its employees like name, e-mail address and designation.
                                                The Licensor may collect any information which is required to
                                                store and verify. There is certain information which is also
                                                collected automatically such as Internet Protocol (IP) address,
                                                browser type, access time etc. IP Address is received
                                                automatically from the Licensee and/or User internet browser and
                                                recorded into the Licensee and/or User’s server logs.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="b.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Information Use:{" "}
                                            <span className="p">
                                                Collabworx platform uses information like Internet Protocol (IP)
                                                address, browser type, access time and Licensee and/or User or its
                                                employee’s information to maintain the quality of its service.
                                            </span>
                                        </h4>
                                    </li>
                                    <li data-list-text="c.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Information Sharing and Disclosing Personal Information to Third
                                            Parties:{" "}
                                            <span className="p">
                                                Any information may be disclosed to Licensor’s other companies,
                                                agents, service providers or other companies working for the
                                                Licensor like processing payments, provision of data storage,
                                                hosting the Software
                                            </span>
                                        </h4>

                                        <p
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "0pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            platform, marketing the Licensor’s services, organizing audits and
                                            performing web analysis. Additionally, the Licensor reserves the
                                            right to disclose a Licensee and/or User or its employees’
                                            information, without notice, if required to do so by any law for the
                                            time being in force or if it is necessary to comply with legal
                                            process prevailing in the country or if it is necessary to protect
                                            the rights and property of Licensor or to protect the rights and
                                            property of other Licensee and/or User of Collabworx platform.
                                        </p>
                                    </li>
                                    <li data-list-text="d.">
                                        <h4
                                            style={{
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Information Security:{" "}
                                            <span className="p">
                                                The Licensor secures the information provided by the Licensee
                                                and/or User or its employees on computer servers in a controlled
                                                and protected environment to prevent loss, misuse, unauthorized
                                                access, disclosure, alteration and destruction of the information.
                                                It is necessary that Licensee and/or User or its employees keep
                                                their information including, but not limited to, login id and
                                                password confidential and must not share it with anyone to prevent
                                                unauthorized use or access of their information. The Licensor will
                                                not be held responsible for any misuse of Collabworx platform by
                                                the Licensee or its employees. Misuse of Collabworx platform data
                                                by the Licensee or its employees includes but not limited to
                                                sharing the data, selling data, providing incorrect information,
                                                removing/erasing records, intentional tampering with data. The
                                                Licensor shall not sell, trade or lease collected information
                                                without the Licensee and/or User or its employee’s consent.
                                            </span>
                                        </h4>

                                    </li>
                                    <li data-list-text="e.">
                                        <h4
                                            style={{
                                                paddingTop: "3pt",
                                                paddingLeft: "41pt",
                                                textIndent: "-18pt",
                                                lineHeight: "200%",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Use of Cookies:{" "}
                                            <span className="p">
                                                A cookie is a small text file which is automatically created on
                                                the hard disk of your computer by a web page server. Cookies are
                                                not used to run programs or transmit viruses to your computer.
                                                Cookies are uniquely assigned to the Licensee and/or User or its
                                                employee’s and can only be read by a web server in the domain that
                                                issued the cookie to the Licensee or its employee’s. The
                                                Collabworx platform does not/does use cookies.
                                            </span>
                                        </h4>
                                        <p
                                            style={{ paddingTop: "16pt", textIndent: "0pt", textAlign: "left" }}
                                        >
                                            <br />
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text={20}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    USAGE OUTSIDE INDIA:{" "}
                                    <span className="p fs-4">
                                        Collabworx Platform is not restricted to users within India and may be
                                        accessed globally.
                                    </span>
                                </h2>
                                <p style={{ paddingTop: "15pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={21}>
                                <h2
                                    style={{ paddingLeft: "26pt", textIndent: "-21pt", textAlign: "left" }}
                                >
                                    TECHNICAL SUPPORT AND CUSTOMER SERVICE:
                                    <span className="p">Collabworx</span>
                                </h2>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <h4
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "0pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    <span className="p">

                                    Platform provides technical support through various channels, including
                                    ticketing, email, and phone, to assist Licensee and/or Users with issues
                                    related to accessing and using Collabworx Platform. Support hours and
                                    response times may vary based on the nature of the issue. The scope of
                                    support is limited to troubleshooting access and functional issues;
                                    training and advanced guidance may be outside the standard support
                                    scope.
                                    </span>
                                </h4>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={22}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    BACKUP AND DATA RECOVERY:{" "}
                                    <span className="p fs-4">
                                        The Licensor is solely responsible for backing up any important data
                                        related to Collabworx Platform. The Licensor’s data retention policy
                                        allows for the deletion of inactive projects after
                                    </span>
                                </h2>
                                <h4
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "19pt",
                                        textIndent: "0pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    <span className="p">

                                    07 (Seven) years of non-payment. The Licensor disclaims liability for
                                    any data loss due to Licensee and/or User error or project deletion
                                    following the retention period.
                                    </span>
                                </h4>
                                <p style={{ paddingTop: "16pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>

                            </li>
                            <li data-list-text={23}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    AUDIT RIGHTS:{" "}
                                    <span className="p fs-4">
                                        The Licensor reserves the right to conduct audits, with or without
                                        notice, to verify that the Licensee is in compliance with the terms of
                                        this Agreement, including the number of Users and devices accessing
                                        the Platform. Any non-compliance found during such an audit may result
                                        in additional fees or termination of the license.
                                    </span>
                                </h2>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={24}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    CHANGES TO THE AGREEMENT:{" "}
                                    <span className="p fs-4">
                                        Collabworx Platform reserves the right to modify this Agreement. Any
                                        such changes will be effective upon notification to Licensee via
                                        email, in-Platform notifications, or other suitable means. Continued
                                        use of Collabworx Platform following notice of changes constitutes
                                        acceptance of the revised terms.
                                    </span>
                                </h2>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={25}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    EQUITABLE RELIEF:
                                    <span className="p fs-4">
                                        In the event of any actual or threatened breach by Licensee and/or
                                        User, the Licensor may be entitled, in addition to such other legal or
                                        equitable remedies which might be available, to seek an immediate
                                        injunction in a competent court of jurisdiction enjoining any breach
                                        or threatened breach of this Agreement. It is clarified that nothing
                                        in this Agreement is intended to prevent Licensor from obtaining
                                        injunctive relief in
                                    </span>
                                </h2>
                                <h4
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "19pt",
                                        textIndent: "0pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    <span className="p">

                                    court to prevent irreparable harm pending the conclusion of any
                                    arbitration. Each Party shall bear its own attorneys’ fees and costs in
                                    proceedings brought pursuant to this paragraph unless such court
                                    determined otherwise.
                                    </span>
                                </h4>
                                <p style={{ paddingTop: "16pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>

                            </li>
                            <li data-list-text={26}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    SEVERABILITY:{" "}
                                    <span className="p fs-4">
                                        If any provision of this Agreement should be held invalid, illegal or
                                        unenforceable, the validity, legality and enforceability of the
                                        remaining provisions will not in any way be affected or impaired
                                        thereby, and such provision will be deemed restated to reflect the
                                        original intention of the Parties as nearly as possible in accordance
                                        with applicable law.
                                    </span>
                                </h2>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={27}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    ASSIGNMENT:{" "}
                                    <span className="p fs-4">
                                        Neither Party may assign or transfer this Agreement and License
                                        without the prior written consent of the other Party. Any purported
                                        assignment or transfer of this Agreement by one Party without the
                                        other Party’s consent will be null and void. This Agreement will be
                                        binding on the Parties and their respective successors and permitted
                                        assigns.
                                    </span>
                                </h2>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={28}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                    className="fs-4"
                                >
                                    NOTICES:{" "}
                                    <a href="mailto:sakulkarni@vkarch.com" className="s5" target="_blank">
                                        Any notice or other communication given pursuant to this Agreement
                                        must be in writing and (a) delivered personally or by courier, or (b)
                                        sent by facsimile or electronic mail transmission, or (c) sent by
                                        registered mail with acknowledgment due, postage prepaid. In respect
                                        of the Licensor, at the email address{" "}
                                    </a>
                                    <span
                                        style={{
                                            color: "black",
                                            fontFamily: '"Times New Roman", serif',
                                            fontStyle: "normal",
                                            fontWeight: "normal",
                                            textDecoration: "underline",
                                            fontSize: "14pt"
                                        }}
                                    >
                                        sakulkarni@vkarch.com
                                    </span>
                                    <span className="p"> and in respect of Licensee and/or User,</span>
                                </h2>
                                <p
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "19pt",
                                        textIndent: "0pt",
                                        lineHeight: "200%",
                                        textAlign: "left"
                                    }}
                                >
                                    at the mail address provided by the Licensee at the time of purchase of
                                    the said license.
                                </p>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>

                            </li>
                            <li data-list-text={29}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    ENTIRE AGREEMENT:
                                    <span className="p fs-4">
                                        This Agreement including the Annexure and the SOWs constitutes the
                                        entire and final and exclusive statement of the agreement between the
                                        Parties with respect to the subject matter hereof.
                                    </span>
                                </h2>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={30}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    AMENDMENT
                                    <span className="p fs-4">
                                        : This Agreement shall not be amended, altered, or modified except by
                                        an instrument in writing expressly referring to this Agreement and
                                        signed by the Parties.
                                    </span>
                                </h2>
                                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                            </li>
                            <li data-list-text={31}>
                                <h2
                                    style={{
                                        paddingLeft: "19pt",
                                        textIndent: "-14pt",
                                        lineHeight: "200%",
                                        textAlign: "justify"
                                    }}
                                >
                                    CONTACT INFORMATION:{" "}
                                    <a href="mailto:sakulkarni@vkarch.com" className="s5 fs-4" target="_blank">
                                        In case of any feedback/complaints/queries relating to this EULA, the
                                        Licensee and/or User may please contact the Licensor by email id:
                                        sakulkarni@vkarch.com.
                                    </a>
                                </h2>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}

export default EulaPolicy;
