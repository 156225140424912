import useReq from "../../../hook/useReq";

const EnachMandate = () => {
//   const { error, loading, requestData, response, clear } = useReq();


  return (
    <>
        <h1>Enatch Mandate</h1>
    </>
  );
};

export default EnachMandate;
