import React from "react";
import Footer from "../Home/Footer";

function TermsOfUse(props) {
  return (
    <div>
      <div className="container">
        <div className="card my-20">
          <h1 className="text-center fs-1 terms-heading text-light">
            Privacy Policy, Terms & Conditions
          </h1>
          <div className="card-body">
            <h2 className="terms-sub-heading">
              Introduction to Privacy Policy
            </h2>
            <p className="fs-4">
              This privacy policy (the "Privacy Policy") applies to your use of
              the Collabworx and any associated services provided by AHOU Enterprises Pvt Ltd (collectively referred to as the "App"). The Privacy
              Policy governs your access to and use of the App, including any
              content, functionality, and services offered on or through the
              App, but does not apply to any third-party websites that may be
              linked to from the App, or any relationships you may have with
              businesses listed or advertised in the App.
            </p>
            <p className="fs-4">
              In this document, the terms "we", "our", and "us" refer to AHOU Enterprises Pvt Ltd, while the terms "you", "your", and "user" refer to
              you, as a user of the App. The term "Personal Information" is
              defined as any information you provide to us that personally
              identifies you, such as your name, phone number, email address,
              and any other information that is associated with such data. Our
              practices and procedures regarding the collection, use, and
              protection of Personal Information are outlined below to ensure a
              secure usage experience for you.
            </p>
            <h2 className="terms-sub-heading">
              Information We Collect and How We Use It
            </h2>
            <p className="fs-4">
              In our commitment to ensuring the sustainability of construction
              projects, the Collabworx collects, receives, and stores your
              Personal Information. This includes, but is not limited to, your
              name, email address, phone number, and any other details you
              choose to provide. If you connect your third-party account
              credentials to our App ("Third-Party Account Information"), you
              acknowledge that some content and information from those accounts
              may be shared with your account on our platform, provided you have
              authorized such transactions. It is important to understand that
              any Third-Party Account Information we receive will be treated
              with the same level of privacy and security as your Personal
              Information under this Privacy Policy. You have the option not to
              provide certain information, but this may limit your ability to
              register with us or use some of our features and services.
            </p>
            <p className="fs-4">
              We use commercially reasonable efforts to ensure that the
              collection of Personal Information is limited to that which is
              necessary to fulfill the purposes identified below. If we use or
              plan to use your information in a manner different than the
              purpose for which it is collected, then we will ask you for your
              consent prior to such use.
            </p>
            <p className="fs-4">
              The Personal Information collected will be used only for the
              purpose of enabling you to use the services provided by us, to
              help promote a safe service, calibrate consumer interest in our
              products and services, inform you about online offers and updates,
              troubleshoot problems, customize User experience, detect and
              protect us against error, fraud and other criminal activity,
              collect money, enforce our terms and conditions, and as otherwise
              described to you at the time of collection of such information.
            </p>
            <p className="fs-4">
              We are dedicated to respecting your privacy and handling your
              information in an open and transparent manner.
            </p>
            <h2 className="terms-sub-heading">Account Information of Users</h2>
            <p className="fs-4">
              When you create an account with the Collabworx to access the full
              suite of sustainability monitoring and assessment tools, we
              collect Personal Information such as your name, email address, and
              mobile number. For certain advanced functionalities, we may also
              collect and store your Sensitive Personal Data or Information
              (SPDI), which could include financial details like credit card and
              debit card numbers, bank account information, and Know Your
              Customer (KYC) documentation, in compliance with relevant
              regulations and as necessary for the services provided.
            </p>
            <p className="fs-4">
              Your email address is used to send you updates, news, and
              potentially a newsletter, should you opt-in during signup or at
              any point thereafter. These communications may include information
              from other users of the app, such as collaboration invites or
              project updates, aimed at enhancing your collaborative efforts
              within the app. If you prefer not to receive certain
              communications from us, you'll have the option to unsubscribe via
              a link provided at the bottom of our emails.
            </p>
            <p className="fs-4">
              Mobile numbers are utilized for sending transaction alerts and SMS
              notifications based on your preferences. Should you wish to no
              longer receive SMS notifications, you can inform us through our
              support channels to discontinue these messages.
            </p>
            <p className="fs-4">
              The Collabworx is committed to ensuring your Personal Information
              is protected and not disclosed, made public, or sold to any third
              party. Users have the right to erase their Personal Information
              from our records. If you choose to exercise this option, we will
              remove all your stored information from our servers, in accordance
              with our data retention policies and applicable regulations.
            </p>
            <h2 className="terms-sub-heading">Customer Information</h2>
            <p className="fs-4">
              For users making transactions or payments through the Collabworx,
              we may store information such as addresses, mobile numbers, wallet
              details, card details, and email addresses associated with these
              transactions. This information is shared with respective entities
              or projects within the app only when a user opts to share it.
              However, the Collabworx takes no liability for the misuse of this
              information by any project teams or individuals to whom the
              information is shared by the user.
            </p>
            <h2 className="terms-sub-heading">Activity</h2>
            <p className="fs-4">
              We collect data related to your use of the Collabworx, such as
              project searches, page views, browser type, IP address,
              geographical location, requested URL, referring URL, and timestamp
              information. This data helps us manage and secure the app,
              ensuring we provide the best possible service to our users.
              Additionally, we analyze this information in aggregate to assess
              user behavior and preferences, aiming to improve interest and
              engagement in various sustainability metrics and features of the
              app. It's important to note that this aggregate information does
              not allow us to identify individual users. We retain all
              intellectual property rights associated with the app and its
              content. Downloading or copying our content does not transfer any
              rights to you. Our app and its content are protected by copyright
              laws as a collective work and/or compilation. The logos, product
              and service marks, trademarks, copyrights, and other intellectual
              properties ("Our IP") are exclusively owned by AHOU Enterprises Pvt Ltd.
              You are not permitted to display or use Our IP in any manner
              without our prior written consent. Accessing our app or content
              does not grant you any license or rights to Our IP, except as
              expressly provided by us.
            </p>
            <h2 className="terms-sub-heading">Cookies</h2>
            <p className="fs-4">
              The Collabworx uses cookies to uniquely identify your browser and
              enhance the quality of our service. "Cookies" are small pieces of
              data sent from a website and stored on your device while you are
              browsing. We may use both session cookies (expiring when you close
              your browser) and persistent cookies (remaining on your device
              until deleted) to improve app functionality and user experience.
              You can remove persistent cookies by following your browser's help
              file directions. Disabling cookies may affect the functionality of
              the Collabworx. We employ third-party tools that may collect
              anonymous information about your visits to our app and
              interactions with our services. These tools aim to optimize your
              experience and may be used to target advertisements for our
              services without collecting or using any Personal Information.
              These third parties do not have access to identifiable information
              about our users. Users can opt out of this data collection by
              disabling cookies in their browser settings.
            </p>
            <h2 className="terms-sub-heading">Enforcement</h2>
            <p className="fs-4">
              We reserve the right to use the information we collect through the
              Collabworx, including Personal Information, to investigate,
              enforce, and apply our Terms of Service and Privacy Policy. This
              is a necessary step to maintain the integrity of our services and
              ensure user compliance with our guidelines.
            </p>
            <h2 className="terms-sub-heading">Transfer of Information</h2>
            <p className="fs-4">
              The Collabworx limits the sharing of your Personal Information
              with third parties. Information may be shared with financial
              institutions, regulatory agencies, or other entities as required
              by law, or to facilitate the delivery of our services. For
              instance, we may collaborate with banks or environmental
              certification bodies to provide our services. These entities are
              obligated to protect your Personal Information and may only use it
              to deliver the services you have requested. We may share Personal
              Information within our group of companies, including any future
              affiliates, under the condition that they comply with this Privacy
              Policy. In the event of a merger, acquisition, or sale of our
              assets, your Personal Information may be transferred to the
              acquiring entity, subject to the commitments made in this Privacy
              Policy. In certain circumstances, we may disclose Personal
              Information to third parties if we believe in good faith that such
              disclosure is necessary to: Address suspected illegal activities;
              Enforce our Terms of Service and Privacy Policy; Comply with legal
              processes such as warrants, subpoenas, or court orders; Protect
              our rights, safety, or property, or that of our users, affiliates,
              or the public. We may also share aggregated, non-personally
              identifiable information with third parties interested in user
              behavior or in establishing business relationships with us, like
              advertisers or content distributors.
            </p>
            <h2 className="terms-sub-heading">Links</h2>
            <p className="fs-4">
              Our App may contain links to third-party websites or information
              as a convenience to you. These references do not imply our
              endorsement of such third parties or their products/services. We
              do not share Personal Information with these third parties through
              these links and are not responsible for their privacy practices.
              We encourage you to review the privacy policies of any third-party
              sites you interact with.
            </p>
            <h2 className="terms-sub-heading">
              User Access to Personal Information
            </h2>
            <p className="fs-4">
              As a user of the Collabworx, you have the ability to access and
              modify certain aspects of your Personal Information through the
              app's settings or account section. This feature allows you to
              maintain accuracy in the information you've shared with us and
              adjust your privacy preferences as needed.
            </p>
            <h2 className="terms-sub-heading">Security</h2>
            <p className="fs-4">
              Your account with the Collabworx is password protected for your
              privacy and security. We employ industry-standard measures to
              safeguard the Personal Information stored in our database,
              adhering to best practices in information security as detailed on
              our website. Access to your Personal Information is strictly
              limited to employees and contractors who require it to perform
              their job functions, such as customer service personnel. Should
              you have any concerns about the security of your information,
              please reach out to us via our designated contact channel. Please
              be aware that no method of transmission over the internet or
              method of electronic storage is completely secure. While we strive
              to use commercially acceptable means to protect your Personal
              Information, we cannot guarantee its absolute security.
            </p>
            <h2 className="terms-sub-heading">
              Terms and Modifications to This Privacy Policy
            </h2>
            <p className="fs-4">
              We reserve the right to modify this Privacy Policy at any time
              without prior notice. You are encouraged to review this policy
              periodically to stay informed of any changes. Such modifications
              will be effective immediately upon being posted on the app or
              website. It is your responsibility to keep your Personal
              Information up to date, including providing us with your most
              current email address. If at any point you do not agree to any
              changes in our Privacy Policy and do not wish your information to
              be subject to the revised policy, you should immediately
              deactivate your account with us. Continued use of the Collabworx
              following any changes to this Privacy Policy constitutes your
              acceptance of those changes.
            </p>
            <h2 className="terms-sub-heading">Applicable Law</h2>
            <p className="fs-4">
              The use of the Collabworx is governed by and construed in
              accordance with the laws of the jurisdiction in which AHOU Enterprises Pvt Ltd operates. Users agree that any legal actions or
              proceedings arising out of their use of the app shall be brought
              exclusively in the competent courts/tribunals having jurisdiction
              in the area and submit to the jurisdiction of such courts.
            </p>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfUse;
