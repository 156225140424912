import React, { useEffect, useState } from "react";
import { IoNotifications } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import "./components/NotificationMenu.css";
import { Link, useNavigate } from "react-router-dom";
import useReq from "../../hook/useReq";
import LazyImage from "../../components/LazyImage";

const initialNotifications = [
  {
    id: 1,
    title: "File Shared",
    time: new Date(),
    description: "Vaibhav shared a file in Project NextGen Group ",
    projectIconPath:
      "https://st4.depositphotos.com/1958265/19891/i/1600/depositphotos_198911232-stock-photo-crane-construction-building-construction-site.jpg",
    readStatus: true,
    notificationType: "comment",
    fileUploadedPath: null,
    projectId: "66751d2ce29b7c36e099a65b",
    convoID: null,
  },
  {
    id: 2,
    title: "New Message",
    time: new Date("2024-07-13T15:04:00"),
    description:
      "Vaibhav sent you a message: 'Hey, are you available for a quick call?'",
    projectIconPath:
      "https://st4.depositphotos.com/1958265/19891/i/1600/depositphotos_198911232-stock-photo-crane-construction-building-construction-site.jpg",
    readStatus: true,
    notificationType: "follow",
    fileUploadedPath: null,
    projectId: "66751d2ce29b7c36e099a65b",
    convoID: "668659c4ee7f32307874696d",
  },
  {
    id: 3,
    title: "New Group Message",
    time: new Date("2024-07-13T14:22:00"),
    description:
      "Sanket sent a message in Marketing Team group: 'Please review the attached documents.'",
    projectIconPath:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgz6GFuU5ZBUd-GpKSuzANJEAzX2IgEub1bTaDuDKfdFw9Nuz0ogYBpEg_HK_e_4XBaJk&usqp=CAU",
    readStatus: false,
    notificationType: "invite",
    fileUploadedPath: null,
    projectId: "66751d2ce29b7c36e099a65b",
    convoID: "668659c4ee7f32307874696d",
  },

  {
    id: 4,
    title: "Added to Group",
    time: new Date("2024-07-19T12:12:00"),
    description: "You were added to the Development Team group by Sarah",
    projectIconPath:
      "https://st2.depositphotos.com/3275449/9839/v/950/depositphotos_98391578-stock-illustration-construction-site-under-construction-building.jpg",
    readStatus: false,
    notificationType: "comment",
    fileUploadedPath: null,
    projectId: "66751d2ce29b7c36e099a65b",
    convoID: "12345",
  },
  {
    id: 5,
    title: "New message (sanket)",
    time: new Date("2024-07-18T15:04:00"),
    description: "Desc 2",
    projectIconPath:
      "https://st.depositphotos.com/1468291/61590/i/450/depositphotos_615905488-stock-photo-render-conceptual-urban-building-construction.jpg",
    readStatus: false,
    notificationType: "follow",
    fileUploadedPath: null,
    projectId: "66751d2ce29b7c36e099a65b",
    convoID: "668659c4ee7f32307874696d",
  },
  {
    id: 6,
    title: "Notification 3",
    time: new Date("2024-07-13T14:22:00"),
    description: "Desc 3",
    projectIconPath:
      "https://p.turbosquid.com/ts-thumb/yV/U6GLOD/o1Ir3qiv/const_00/jpg/1580907642/300x300/sharp_fit_q85/355333cbc3adbe442ffc08450e20e347233adee1/const_00.jpg",
    readStatus: false,
    notificationType: "invite",
    fileUploadedPath: null,
    projectId: "66751d2ce29b7c36e099a65b",
    convoID: "12347",
  },
  {
    id: 7,
    title: "Notification 4",
    time: new Date("2024-07-18T15:10:00"),
    description: "Desc 2",
    projectIconPath:
      "https://img1.cgtrader.com/items/28017/cad6bcb80f/large/building-construction-site-3d-model-low-poly-max-obj-3ds-fbx-lwo-ma.jpg",
    readStatus: false,
    notificationType: "follow",
    fileUploadedPath: null,
    projectId: "66751d2ce29b7c36e099a66d",
    convoID: "12346",
  },
  {
    id: 8,
    title: "Notification 4",
    time: new Date("2024-07-18T15:10:00"),
    description: "Desc 2",
    projectIconPath:
      "https://static.turbosquid.com/Preview/001237/427/DZ/construction-building-industrial-3D-model_D.jpg",
    readStatus: false,
    notificationType: "follow",
    fileUploadedPath: null,
    projectId: "66751d2ce29b7c36e099a65b",
    convoID: "12346",
  },
  {
    id: 9,
    title: "Notification 4",
    time: new Date(),
    description: "Desc 2",
    projectIconPath:
      "https://www.shutterstock.com/image-photo/construction-concrete-skeleton-highrise-building-260nw-1120184726.jpg",
    readStatus: false,
    notificationType: "follow",
    fileUploadedPath: null,
    projectId: "66751d2ce29b7c36e099a65b",
    convoID: "12346",
  },
];


function NotificationMenu() {
  // const { response, error, requestData } = useReq();

  // const [notifications, setNotifications] = useState(initialNotifications);

  // const [notificationsData, setNotificationsData] = useState();
  
  // const navigate = useNavigate();

  // useEffect(() => {
  //   requestData("get", "/user/getnotifications")
  // }, [])

  // useEffect(() => {
  //   if(response !== null) {
  //     console.log("response...............sd", response)
  //     setNotificationsData(response)
  //   }
  // }, [response])

  // console.log("notificationsData88", notificationsData)

  // const handleNavigateToProject = (notification) => {
  //   navigate(`project/${notification.projectId}`);

  //   if (notification.convoID === null) {
  //     sessionStorage.setItem("nav", "doc");
  //   }
  //   if (notification.convoID) {
  //     sessionStorage.setItem("nav", "com");
  //   }
  // };

  // const getTimeElapsed = (notification) => {
  //   const notificationDate = notification.time;
  //   const now = new Date();

  //   const timeDifference = now.getTime() - notificationDate.getTime();
  //   const seconds = Math.floor(timeDifference / 1000);
  //   const minutes = Math.floor(seconds / 60);
  //   const hours = Math.floor(minutes / 60);
  //   const days = Math.floor(hours / 24);

  //   if (seconds < 1) {
  //     return `Just now`;
  //   } else if (seconds < 60) {
  //     return `${seconds} seconds ago`;
  //   } else if (minutes < 60) {
  //     return `${minutes} minutes ago`;
  //   } else if (hours < 24) {
  //     return `${hours} hours ago`;
  //   } else {
  //     const formattedTime = notificationDate.toLocaleTimeString([], {
  //       hour: "numeric",
  //       minute: "2-digit",
  //     });
  //     return `${days} days ago at ${formattedTime}`;
  //   }
  // };

  // const formatDate = (date) => {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return date.toLocaleDateString(undefined, options);
  // };

  // const groupedNotifications = notifications.reduce((acc, notification) => {
  //   const date = formatDate(notification.time);
  //   if (!acc[date]) {
  //     acc[date] = [];
  //   }
  //   acc[date].push(notification);
  //   return acc;
  // }, {});



  const { response, error, requestData } = useReq();
  const [notificationsData, setNotificationsData] = useState([]);
  const navigate = useNavigate();

  // Fetch notifications from the API
  useEffect(() => {
    requestData("get", "/user/notifications");
  }, []);

  // Update notificationsData once the response is available
  useEffect(() => {
    if (response !== null) {
      console.log("response:", response);
      setNotificationsData(response.notifications); // Ensure notifications array is set
    }
  }, [response]);

  // Navigate to the project and set sessionStorage based on convoID
  const handleNavigateToProject = (notification) => {
    console.log("notification", notification)
    navigate(`project/${notification.projectId}`);
    sessionStorage.setItem("nav", notification.convoId ? "com" : "doc");
  };

  // Calculate the time elapsed since the notification
  const getTimeElapsed = (notification) => {
    const notificationDate = new Date(notification.notificationTime);
    const now = new Date();
    const timeDifference = now.getTime() - notificationDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else {
      const formattedTime = notificationDate.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
      return `${days} days ago at ${formattedTime}`;
    }
  };

  // Format date for grouping (e.g., August 27, 2024)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  // Group notifications by date
  const groupedNotifications = notificationsData.reduce((acc, notification) => {
    const date = formatDate(notification.notificationTime);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(notification);
    return acc;
  }, {});


  // Function to closing a notification
  // const handleCloseNotification = (id) => {
  //   setNotifications(notifications.filter((notification) => notification.id !== id));
  // };

  // Function to marking all notifications as read
  const handleMarkAllAsRead = () => {
    setNotificationsData(
      notificationsData.map((notification) => ({
        ...notification,
        readStatus: true,
      }))
    );
  };

  // Filter unread notifications
  const unreadNotifications = notificationsData.filter(
    (notification) => !notification.readStatus
  );


  return (
    <div>
      <button
        className="text-white p-1"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <IoNotifications size={24} />
        <span
          className="notification-count fs-5 bg-light text-dark bolder"
          style={{
            borderRadius: "50%",
            padding: "2px 5px",
          }}
        >
          {unreadNotifications.length || 0}
        </span>
      </button>
      <div
        className="offcanvas offcanvas-end w-100 w-md-75 w-lg-500px"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div
          className="offcanvas-header"
          style={{ backgroundColor: "#f0f0f0" }}
        >
          <h5
            id="offcanvasRightLabel"
            className="fs-2"
            style={{ color: "#264858", zIndex: 1 }}
          >
            <IoMdNotificationsOutline size={22} /> Notifications
            <span
              className="notification-count fs-5 text-light ms-2 rounded-3 staticblue"
              style={{
                padding: "0px 7px",
              }}
            >
              {unreadNotifications.length || 0}
            </span>
          </h5>
          <div
            className="p-5 cursor-pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
          >
            <img src="/assets/icons/close-blue.png" alt="close" width={16} />
          </div>
        </div>

        <div className="container overflow-scroll">
          {/* {unreadNotifications.length > 0 && ( */}
          <div className={`d-flex my-5 text-dark`}>
            <div
              className={`ms-auto me-4 ${
                unreadNotifications.length ? "cursor-pointer" : "cursor-default"
              }`}
            >
              <span className="text-muted fs-5" onClick={handleMarkAllAsRead}>
                <u>Mark all as read.</u>
              </span>
            </div>
          </div>
          {/* )} */}

          {unreadNotifications.length === 0 && (
            <div className="no-notifications">
              <img
                src="/assets/icons/notifications.png"
                alt="No Notifications"
                width={100}
              />
              <p className="no-notifications-text">No Notifications</p>
              {/* <p className="no-notifications-subtext">You're all caught up!</p> */}
            </div>
          )}

          
          {Object.keys(groupedNotifications).map((date) => (
            <div key={date}>
             
              <h6 className="notification-date">{date}</h6>
              {groupedNotifications[date]
                // .filter((notification) => !notification.readStatus)
                .map((notification) => (
                  <>
                    <div
                      className="notification cursor-pointer"
                      key={notification.id}
                      style={{ position: "relative" }}
                      onClick={() => handleNavigateToProject(notification)}
                    >
                      {/* <button
                        className="close-button"
                        onClick={() => handleCloseNotification(notification.id)}
                      >
                        &times;
                      </button> */}
                      {/* <img
                        src={notification.logo}
                        alt="Project Image"
                      /> */}
                      <LazyImage
                        style={""}
                        src={
                          notification ? process.env.REACT_APP_SOCKETIO + notification.logo : ""
                        }
                      />
                      <div className="notification-content">
                        <div className="notification-header">
                          <div
                            className={`notification-type ${
                              notification.readStatus ? "text-muted" : ""
                            }`}
                          >
                            {notification.notificationTitle}
                          </div>
                          <div className="notification-time">
                            {getTimeElapsed(notification)}
                          </div>
                        </div>
                        <div
                          className={`notification-description ${
                            notification.readStatus ? "text-muted" : ""
                          }`}
                        >
                          {notification.notificationDesc}
                        </div>
                      </div>
                    </div>
                    <hr className="text-dark" />
                  </>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NotificationMenu;





// import React, { useEffect, useState } from "react";
// import { IoNotifications } from "react-icons/io5";
// import { IoMdNotificationsOutline } from "react-icons/io";
// import "./components/NotificationMenu.css";
// import { Link, useNavigate } from "react-router-dom";
// import useReq from "../../hook/useReq";
// import Swal from "sweetalert2";

// // const initialNotifications = [
// //   {
// //     id: 1,
// //     title: "File Shared",
// //     time: new Date(),
// //     description: "Vaibhav shared a file in Project NextGen Group ",
// //     projectIconPath:
// //       "https://st4.depositphotos.com/1958265/19891/i/1600/depositphotos_198911232-stock-photo-crane-construction-building-construction-site.jpg",
// //     readStatus: true,
// //     notificationType: "comment",
// //     fileUploadedPath: null,
// //     projectId: "66751d2ce29b7c36e099a65b",
// //     convoID: null,
// //   },
// //   {
// //     id: 2,
// //     title: "New Message",
// //     time: new Date("2024-07-13T15:04:00"),
// //     description:
// //       "Vaibhav sent you a message: 'Hey, are you available for a quick call?'",
// //     projectIconPath:
// //       "https://st4.depositphotos.com/1958265/19891/i/1600/depositphotos_198911232-stock-photo-crane-construction-building-construction-site.jpg",
// //     readStatus: true,
// //     notificationType: "follow",
// //     fileUploadedPath: null,
// //     projectId: "66751d2ce29b7c36e099a65b",
// //     convoID: "668659c4ee7f32307874696d",
// //   },
// //   {
// //     id: 3,
// //     title: "New Group Message",
// //     time: new Date("2024-07-13T14:22:00"),
// //     description:
// //       "Sanket sent a message in Marketing Team group: 'Please review the attached documents.'",
// //     projectIconPath:
// //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgz6GFuU5ZBUd-GpKSuzANJEAzX2IgEub1bTaDuDKfdFw9Nuz0ogYBpEg_HK_e_4XBaJk&usqp=CAU",
// //     readStatus: false,
// //     notificationType: "invite",
// //     fileUploadedPath: null,
// //     projectId: "66751d2ce29b7c36e099a65b",
// //     convoID: "668659c4ee7f32307874696d",
// //   },

// //   {
// //     id: 4,
// //     title: "Added to Group",
// //     time: new Date("2024-07-19T12:12:00"),
// //     description: "You were added to the Development Team group by Sarah",
// //     projectIconPath:
// //       "https://st2.depositphotos.com/3275449/9839/v/950/depositphotos_98391578-stock-illustration-construction-site-under-construction-building.jpg",
// //     readStatus: false,
// //     notificationType: "comment",
// //     fileUploadedPath: null,
// //     projectId: "66751d2ce29b7c36e099a65b",
// //     convoID: "12345",
// //   },
// //   {
// //     id: 5,
// //     title: "New message (sanket)",
// //     time: new Date("2024-07-18T15:04:00"),
// //     description: "Desc 2",
// //     projectIconPath:
// //       "https://st.depositphotos.com/1468291/61590/i/450/depositphotos_615905488-stock-photo-render-conceptual-urban-building-construction.jpg",
// //     readStatus: false,
// //     notificationType: "follow",
// //     fileUploadedPath: null,
// //     projectId: "66751d2ce29b7c36e099a65b",
// //     convoID: "668659c4ee7f32307874696d",
// //   },
// //   {
// //     id: 6,
// //     title: "Notification 3",
// //     time: new Date("2024-07-13T14:22:00"),
// //     description: "Desc 3",
// //     projectIconPath:
// //       "https://p.turbosquid.com/ts-thumb/yV/U6GLOD/o1Ir3qiv/const_00/jpg/1580907642/300x300/sharp_fit_q85/355333cbc3adbe442ffc08450e20e347233adee1/const_00.jpg",
// //     readStatus: false,
// //     notificationType: "invite",
// //     fileUploadedPath: null,
// //     projectId: "66751d2ce29b7c36e099a65b",
// //     convoID: "12347",
// //   },
// //   {
// //     id: 7,
// //     title: "Notification 4",
// //     time: new Date("2024-07-18T15:10:00"),
// //     description: "Desc 2",
// //     projectIconPath:
// //       "https://img1.cgtrader.com/items/28017/cad6bcb80f/large/building-construction-site-3d-model-low-poly-max-obj-3ds-fbx-lwo-ma.jpg",
// //     readStatus: false,
// //     notificationType: "follow",
// //     fileUploadedPath: null,
// //     projectId: "66751d2ce29b7c36e099a66d",
// //     convoID: "12346",
// //   },
// //   {
// //     id: 8,
// //     title: "Notification 4",
// //     time: new Date("2024-07-18T15:10:00"),
// //     description: "Desc 2",
// //     projectIconPath:
// //       "https://static.turbosquid.com/Preview/001237/427/DZ/construction-building-industrial-3D-model_D.jpg",
// //     readStatus: false,
// //     notificationType: "follow",
// //     fileUploadedPath: null,
// //     projectId: "66751d2ce29b7c36e099a65b",
// //     convoID: "12346",
// //   },
// //   {
// //     id: 9,
// //     title: "Notification 4",
// //     time: new Date(),
// //     description: "Desc 2",
// //     projectIconPath:
// //       "https://www.shutterstock.com/image-photo/construction-concrete-skeleton-highrise-building-260nw-1120184726.jpg",
// //     readStatus: false,
// //     notificationType: "follow",
// //     fileUploadedPath: null,
// //     projectId: "66751d2ce29b7c36e099a65b",
// //     convoID: "12346",
// //   },
// // ];

// function NotificationMenu() {
//   const { response: notificationResponse, error: notificationError, requestData: notificationRequestData, loading: notificationLoading } = useReq();

//   // const [notifications, setNotifications] = useState(initialNotifications);

//   // const { response, error, requestData, loading } = useReq();
//   const [projects, setProjects] = useState([]);
//   const [notifications, setNotifications] = useState([]);
//   const [groupedNotifications, setGroupedNotifications] = useState({});

//   const navigate = useNavigate();

//   function handleFetchNotification() {
//     notificationRequestData("GET", "/user/getprojects");
//   }

//   // console.log("projects", projects);


//   useEffect(() => {
//     if (notificationResponse) {
//       Swal.close();
//       setProjects(notificationResponse);
//       console.log("RRRRRRRRRRRRR", notificationResponse.projects);

//       const allNotifications = notificationResponse.projects.flatMap(
//         (project) => project.notifications
//       );
//       setNotifications(allNotifications);
//     }
//   }, [notificationResponse]);


//   useEffect(() => {
//     if(notificationError) {
//       Swal.fire({
//         title: "Filed to Fetch Notifications",
//         text: `Error`,
//         icon: "error",
//         iconColor: "red",
//         showConfirmButton: true,
//         confirmButtonText: "Try again later",
//         confirmButtonColor: "#0a5c7b",
//       });
//     }
//   }, [notificationError])

//   useEffect(() => {
//     if(notificationLoading) {
//       Swal.fire({
//         title: "",
//         html: `<div className="tick ccc-z-index">
//         <div className="tick-mark">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="150px"
//             height="150px"
//             viewBox="0 0 100 100"
//             preserveAspectRatio="xMidYMid"
//           >
//             <circle
//               cx="50"
//               cy="50"
//               fill="none"
//               stroke="#49798d"
//               stroke-width="6"
//               r="32"
//               stroke-dasharray="174.35839227423352 60.119464091411174"
//             >
//               <animateTransform
//                 attributeName="transform"
//                 type="rotate"
//                 repeatCount="indefinite"
//                 dur="2.0408163265306123s"
//                 values="0 50 50;360 50 50"
//                 keyTimes="0;1"
//               ></animateTransform>
//             </circle>
//           </svg>
//         </div>
//         <h2>Loading..</h2>
//       </div>`,
//         allowOutsideClick: false,
//         showCancelButton: false,
//         showConfirmButton: false,
//         onBeforeOpen: () => {
//           Swal.showLoading();
//         },
//       });
//     }
//   }, [notificationLoading]);
//   // useEffect(() => {
//   //   if(notificationError) {

//   //   }
//   // }, [notificationError])

//   const handleNavigateToProject = (notification) => {
//     navigate(`project/${notification.projectId}`);

//     if (notification.convoID === null) {
//       sessionStorage.setItem("nav", "doc");
//     }
//     if (notification.convoID) {
//       sessionStorage.setItem("nav", "com");
//     }
//   };

//   const getTimeElapsed = (notification) => {
//     const notificationDate = notification.date;
//     console.log("notification.time", notification.date);
//     const now = new Date();

//     const timeDifference = now.getTime() - notificationDate.getTime();
//     const seconds = Math.floor(timeDifference / 1000);
//     const minutes = Math.floor(seconds / 60);
//     const hours = Math.floor(minutes / 60);
//     const days = Math.floor(hours / 24);

//     if (seconds < 1) {
//       return `Just now`;
//     } else if (seconds < 60) {
//       return `${seconds} seconds ago`;
//     } else if (minutes < 60) {
//       return `${minutes} minutes ago`;
//     } else if (hours < 24) {
//       return `${hours} hours ago`;
//     } else {
//       const formattedTime = notificationDate.toLocaleTimeString([], {
//         hour: "numeric",
//         minute: "2-digit",
//       });
//       return `${days} days ago at ${formattedTime}`;
//     }
//   };

//   const formatRelativeTime = (date) => {
//     const now = new Date();
//     const diffInSeconds = Math.floor((now - new Date(date)) / 1000);
//     const diffInMinutes = Math.floor(diffInSeconds / 60);
//     const diffInHours = Math.floor(diffInMinutes / 60);
//     const diffInDays = Math.floor(diffInHours / 24);

//     if (diffInSeconds < 60) return "just now";
//     if (diffInMinutes < 60) return `${diffInMinutes} min ago`;
//     if (diffInHours < 24) return `${diffInHours} hr ago`;
//     if (diffInDays === 1) return "1 day ago";
//     return `${diffInDays} days ago`;
//   };

//   const formatDate = (date) => {
//     const options = { year: "numeric", month: "long", day: "numeric" };
//     return date.toLocaleDateString(undefined, options);
//   };

//   useEffect(() => {
//     const groupByDate = notifications.reduce((acc, notification) => {
//       const date = new Date(notification.date).toLocaleDateString(); // Format date as needed
//       if (!acc[date]) {
//         acc[date] = [];
//       }
//       acc[date].push(notification);
//       return acc;
//     }, {});

//     setGroupedNotifications(groupByDate);
//   }, [notifications]);

//   // const groupedNotifications = notifications?.reduce((acc, notification) => {
//   //   const date = formatDate(notification.time);
//   //   if (!acc[date]) {
//   //     acc[date] = [];
//   //   }
//   //   acc[date].push(notification);
//   //   return acc;
//   // }, {});

//   // Function to closing a notification
//   // const handleCloseNotification = (id) => {
//   //   setNotifications(notifications.filter((notification) => notification.id !== id));
//   // };

//   // Function to marking all notifications as read
//   const handleMarkAllAsRead = () => {
//     setNotifications(
//       notifications.map((notification) => ({
//         ...notification,
//         readStatus: true,
//       }))
//     );
//   };

//   // Filter unread notifications
//   const unreadNotifications = notifications.filter(
//     (notification) => !notification.readStatus
//   );

//   console.log("notifications", notifications);

//   return (
//     <div>
//       <button
//         className="text-white p-1"
//         data-bs-toggle="offcanvas"
//         data-bs-target="#offcanvasRight"
//         aria-controls="offcanvasRight"
//         onClick={handleFetchNotification}
//       >
//         <IoNotifications size={24} />
//         <span
//           className="notification-count fs-5 bg-light text-dark bolder"
//           style={{
//             borderRadius: "50%",
//             padding: "2px 5px",
//           }}
//         >
//           {unreadNotifications.length || 0}
//         </span>
//       </button>
//       <div
//         className="offcanvas offcanvas-end w-100 w-md-75 w-lg-500px"
//         tabIndex={-1}
//         id="offcanvasRight"
//         aria-labelledby="offcanvasRightLabel"
//       >
//         <div
//           className="offcanvas-header"
//           style={{ backgroundColor: "#f0f0f0" }}
//         >
//           <h5
//             id="offcanvasRightLabel"
//             className="fs-2"
//             style={{ color: "#264858", zIndex: 1 }}
//           >
//             <IoMdNotificationsOutline size={22} /> Notifications
//             <span
//               className="notification-count fs-5 text-light ms-2 rounded-3 staticblue"
//               style={{
//                 padding: "0px 7px",
//               }}
//             >
//               {unreadNotifications.length || 0}
//             </span>
//           </h5>
//           <div
//             className="p-5 cursor-pointer"
//             data-bs-dismiss="offcanvas"
//             aria-label="Close"
//             style={{
//               transform: "translateY(-50%)",
//               zIndex: 1,
//             }}
//           >
//             <img src="/assets/icons/close-blue.png" alt="close" width={16} />
//           </div>
//         </div>

//         <div className="container overflow-scroll">
//           {/* {unreadNotifications.length > 0 && ( */}
//           <div className={`d-flex my-5 text-dark`}>
//             <div
//               className={`ms-auto me-4 ${
//                 unreadNotifications.length ? "cursor-pointer" : "cursor-default"
//               }`}
//             >
//               <span className="text-muted fs-5" onClick={handleMarkAllAsRead}>
//                 <u>Mark all as read.</u>
//               </span>
//             </div>
//           </div>
//           {/* )} */}

//           {/* {unreadNotifications.length === 0 && (
//             <div className="no-notifications">
//               <img
//                 src="/assets/icons/notifications.png"
//                 alt="No Notifications"
//                 width={100}
//               />
//               <p className="no-notifications-text">No Notifications</p>
//               <p className="no-notifications-subtext">You're all caught up!</p>
//             </div>
//           )} */}

//           {Object.keys(groupedNotifications).map((date) => (
//             <div key={date}>
//               {console.log(
//                 "groupedNotifications.readStatus",
//                 groupedNotifications
//               )}
//               <h6 className="notification-date">{date}</h6>
//               {groupedNotifications[date]
//                 // .filter((notification) => !notification.readStatus)
//                 .map((notification) => (
//                   <>
//                     <div
//                       className="notification cursor-pointer"
//                       key={notification.id}
//                       style={{ position: "relative" }}
//                       onClick={() => handleNavigateToProject(notification)}
//                     >
//                       {/* <button
//                         className="close-button"
//                         onClick={() => handleCloseNotification(notification.id)}
//                       >
//                         &times;
//                       </button> */}
//                       <img
//                         src={notification.projectIconPath}
//                         alt="Project Image"
//                       />
//                       <div className="notification-content">
//                         <div className="notification-header">
//                           <div
//                             className={`notification-type ${
//                               notification.readStatus ? "text-muted" : ""
//                             }`}
//                           >
//                             {notification.NotificationType}
//                           </div>
//                           <div className="notification-time">
//                             {/* {getTimeElapsed(notification)} */}
//                             {formatRelativeTime(notification.date)}
//                           </div>
//                         </div>
//                         <div
//                           className={`notification-description ${
//                             notification.readStatus ? "text-muted" : ""
//                           }`}
//                         >
//                           {notification.disc}
//                         </div>
//                       </div>
//                     </div>
//                     <hr className="text-dark" />
//                   </>
//                 ))}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default NotificationMenu;

// // import React, { useState, useEffect } from 'react';

// // import useReq from '../../hook/useReq';

// // const NotificationMenu = () => {
// //   const { response, error, requestData, loading } = useReq();
// //   const [projects, setProjects] = useState([]);
// //   const [notifications, setNotifications] = useState([]);

// //   function handleFetchNotification() {
// //         requestData("GET", "/user/getprojects");
// //       }

// //   useEffect(() => {
// //     handleFetchNotification();
// //   }, []);

// //   useEffect(() => {
// //     if(response) {
// //       setProjects(response)
// //       console.log("RRRRRRRRRRRRR", response.projects)

// //       const allNotifications = response.projects.flatMap(project => project.notifications);
// //       setNotifications(allNotifications);
// //     }
// //   }, [response]);

// //   console.log("NOTIFICATIONS", notifications);

// //   return (
// //     <div>
// //       <h2>Notifications</h2>
// //       <ul>
// //         {notifications.map(notification => (
// //           <li key={notification._id}>
// //             <strong>{notification.NotificationType}</strong>: {notification.disc}
// //             <br />
// //             <small>{new Date(notification.date).toLocaleString()}</small>
// //           </li>
// //         ))}
// //       </ul>
// //     </div>
// //   );
// // };

// // export default NotificationMenu;

// // import React, { useState, useEffect } from 'react';
// // import useReq from '../../hook/useReq';

// // const NotificationMenu = () => {
// //   const { response, error, requestData, loading } = useReq();
// //   const [projects, setProjects] = useState([]);
// //   const [notifications, setNotifications] = useState([]);
// //   const [groupedNotifications, setGroupedNotifications] = useState({});

// //   function handleFetchNotification() {
// //     requestData("GET", "/user/getprojects");
// //   }

// //   useEffect(() => {
// //     handleFetchNotification();
// //   }, []);

// //   useEffect(() => {
// //     if (response) {
// //       setProjects(response);
// //       console.log("RRRRRRRRRRRRR", response.projects);

// //       const allNotifications = response.projects.flatMap(project => project.notifications);
// //       setNotifications(allNotifications);
// //     }
// //   }, [response]);

// //   useEffect(() => {
// //     const groupByDate = notifications.reduce((acc, notification) => {
// //       const date = new Date(notification.date).toLocaleDateString(); // Format date as needed
// //       if (!acc[date]) {
// //         acc[date] = [];
// //       }
// //       acc[date].push(notification);
// //       return acc;
// //     }, {});

// //     setGroupedNotifications(groupByDate);
// //   }, [notifications]);

// //   console.log("GROUPED NOTIFICATIONS", groupedNotifications);

// //   return (
// //     <div>
// //       <h2>Notifications</h2>
// //       {Object.keys(groupedNotifications).map(date => (
// //         <div key={date}>
// //           <h3>{date}</h3>
// //           <ul>
// //             {groupedNotifications[date].map(notification => (
// //               <li key={notification._id}>
// //                 <strong>{notification.NotificationType}</strong>: {notification.disc}
// //                 <br />
// //                 <small>{new Date(notification.date).toLocaleTimeString()}</small>
// //               </li>
// //             ))}
// //           </ul>
// //         </div>
// //       ))}
// //     </div>
// //   );
// // };

// // export default NotificationMenu;
