import React, { Suspense, useContext, useEffect, useRef, useState } from "react";
import { UsersContext } from "../../../context/usersContext";
import useReq from "../../../hook/useReq";

const UserDetails = () => {
    const [avatar, setAvatar] = useState();
    const [username, setUsername] = useState();
    const [mobileNo, setMobileNo] = useState();
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [cnfNewPassword, setCnfNewPassword] = useState("");
    const [role, setRole] = useState("");
    const [categoryOfCompany, setCategoryOfCompany] = useState("");
    const { error, requestData, response, clear } = useReq();
    const [authError, setAuthError] = useState("");
    const [progress, setprogress] = useState(false);
    const ctx = useContext(UsersContext);
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);


    const [isEditable, setIsEditable] = useState(false);
    const [showPasswordForm, setPasswordForm] = useState(false);

    const toggleEdit = () => {
        setIsEditable(!isEditable);
    };

    console.log("avatar", ctx.avatar)

    const handleUpdate = () => {
        setprogress(true);
        if (
            username === "" ||
            mobileNo === "" ||
            currentPassword === "" ||
            cnfNewPassword === "" ||
            newPassword === "" ||
            role === "" ||
            categoryOfCompany === ""
        ) {
            setAuthError("All the Fields Are Mandatory*");
            setprogress(false);
            return;
        }
        if (!/^[a-zA-Z]([a-zA-Z]+){2,}(\s[a-zA-Z]([a-zA-Z]+)*)?$/.test(username)) {
            setAuthError(
                "Only alphabets allowed in name & only first and last name is allowed"
            );
            setprogress(false);
            return;
        }
        if (!/^([+]\d{2})?\d{10}$/.test(mobileNo)) {
            setAuthError("Enter a valid Mobile Number!");
            setprogress(false);
            return;
        }
        if (newPassword) {
            if (
                !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                    newPassword
                )
            ) {
                setAuthError(
                    "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"
                );
                setprogress(false);
                return;
            }
        }
        if (newPassword !== cnfNewPassword) {
            setAuthError("New Password Does Not Match with Confirm Password*");
            setprogress(false);
            return;
        }

        const formData = new FormData();
        formData.append("username", username);
        formData.append("mobileNo", mobileNo);
        formData.append("currentPassword", currentPassword);
        formData.append("newPassword", newPassword);
        formData.append("role", role);
        formData.append("categoryOfCompany", categoryOfCompany);
        formData.append("image", file);

        requestData("POST", "/user/updateProfile", formData);
    };

    // const handleButtonClick = () => {
    //     if (isEditable) {
    //         // When saving, call handleUpdate() and disable edit mode
    //         handleUpdate();
    //         setIsEditable(false);
    //     } else {
    //         // When editing, enable edit mode
    //         setIsEditable(true);
    //     }
    // };

    const handleButtonClick = async () => {
        if (isEditable) {
            try {
                // Call handleUpdate() and wait for the response
                const response = await handleUpdate();

                // Check if the update was successful (assuming `response.success` is a boolean)
                if (response.success) {
                    // Disable edit mode only if the update was successful
                    setIsEditable(false);
                } else {
                    // Handle failure case (optional: display an error message)
                    console.error("Update failed", response.error);
                }
            } catch (error) {
                // Handle any error that occurred during the update process
                console.error("An error occurred while updating", error);
            }
        } else {
            // Enable edit mode
            setIsEditable(true);
        }
    };


    useEffect(() => {
        setUsername((prev) => ctx.username);
        setMobileNo((prev) => ctx.mobileNo);
        setAvatar((prev) => ctx.avatar);
        setRole((prev) => ctx.role);
        setCategoryOfCompany((prev) => ctx.categoryOfCompany);
    }, []);

    useEffect(() => {
        if (response && response.success && response.existingUser) {
            ctx.username = response.existingUser.username;
            ctx.isFirstLogin = response.existingUser.isFirstLogin;
            ctx.mobileNo = response.existingUser.mobileNo;
            ctx.role = response.existingUser.role;
            ctx.categoryOfCompany = response.existingUser.categoryOfCompany;
            ctx.avatar = response.existingUser.avatar;

            setprogress(false);
        }
    }, [response, ctx]);

    const handleInputChange = (event) => {
        let pickedFile;

        if (event.target.files && event.target.files.length === 1) {
            pickedFile = event.target.files[0];
            setFile(pickedFile);

            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target.result;
                const elements = document.querySelectorAll(".image-input-placeholder");

                // Set the new background image using the data URL
                elements.forEach((element) => {
                    element.style.backgroundImage = "url(" + imageDataUrl + ")";
                });
            };

            reader.readAsDataURL(pickedFile);
        }
    };

    useEffect(() => {
        setprogress(false);
    }, [error]);
    const handleDivClick = () => {
        // Trigger the file input programmatically
        fileInputRef.current.click();
    };

    console.log("ctx.avatar", ctx.avatar)


    useEffect(() => {
        const img = document.getElementById("inputImage");
        const imageDataUrl =
            process.env.REACT_APP_SOCKETIO + ctx.avatar?.replace(/\\/g, "/");
        img.style.backgroundImage = `url("${imageDataUrl}")`;
    }, [ctx.avatar]);



    // console.log("process.env.REACT_APP_SOCKETIO + ctx.avatar", process.env.REACT_APP_SOCKETIO + ctx.avatar.replace(/\\/g, "/"))
    console.log("ctx.avatar", ctx.avatar)

    return (
        <div>
            <div className="row h-100">
                <main className="col p-4">
                    <div className="card shadow-sm p-6">
                        <div className="d-flex align-items-center">
                            <div
                                className="image-input image-input-empty image-input-outline image-input-placeholder"
                                data-kt-image-input="true"
                                id="inputImage"
                            >
                                <div
                                    className="image-input-wrapper w-80px h-80px border-1 border-gray-300"
                                    onClick={handleDivClick}
                                    style={{ cursor: "pointer" }}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleInputChange}
                                    ref={fileInputRef}
                                    style={{ display: "none" }} // hide the input field
                                />

                                <span
                                    className="btn btn-icon btn-circle  w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="cancel"
                                    data-bs-toggle="tooltip"
                                    title="Cancel Project Logo"
                                >
                                    <i className="bi bi-x fs-2" />
                                </span>
                                <span
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="remove"
                                    data-bs-toggle="tooltip"
                                    title="Remove Project Logo"
                                >
                                    <i className="bi bi-x fs-2" />
                                </span>
                            </div>


                            <div className="ms-3">
                                <h4>{username}</h4>
                                <p className="text-muted">{ctx.email}</p>
                            </div>
                            <button className="btn  staticgreen ms-auto" onClick={handleButtonClick}>
                                {isEditable ? 'Save' : 'Edit'}
                            </button>
                        </div>
                        <div className="form-text">
                            Click on profile picture to change
                        </div>
                        <br />
                        {error && <p style={{ color: "red" }}>{error.message}</p>}
                        {authError && <p style={{ color: "red" }}>{authError}</p>}

                        {/* User Profile Form */}
                        <div className="row mt-4">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input
                                    {...(isEditable ? { type: 'text' } : {})}
                                    className="form-control"
                                    placeholder="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    readOnly={!isEditable}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="mobileNo" className="form-label">Mobile Number</label>
                                <input
                                    {...(isEditable ? { type: 'text' } : {})}
                                    className="form-control"
                                    placeholder="Mobile  Number"

                                    id="mobileNo"
                                    value={mobileNo}
                                    minLength={10}
                                    maxLength={10}
                                    onChange={(e) => {
                                        setMobileNo(e.target.value);
                                    }}
                                    readOnly={!isEditable}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Select a Role*</label>
                                <select className="form-select bg-white"
                                    aria-label="Default select example"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    disabled={!isEditable}>
                                    <option>Select a Role*</option>
                                    <option value="Project Manager">Project Manager</option>
                                    <option value="Site Engineer">Site Engineer</option>
                                    <option value="Construction Manager">
                                        Construction Manager
                                    </option>
                                    <option value="Architect">Architect</option>
                                    <option value="Quantity Surveyor">Quantity Surveyor</option>
                                    <option value="Site Supervisor">Site Supervisor</option>
                                    <option value="Health and Safety Officer">
                                        Health and Safety Officer
                                    </option>
                                    <option value="Civil Engineer">Civil Engineer</option>
                                    <option value="Electrician">Electrician</option>
                                    <option value="Plumber">Plumber</option>
                                    <option value="Heavy Equipment Operator">
                                        Heavy Equipment Operator
                                    </option>
                                    <option value="Carpenter">Carpenter</option>
                                    <option value="Surveyor">Surveyor</option>
                                    <option value="Laborers">Laborers</option>
                                    <option value="Mechanical Engineer">
                                        Mechanical Engineer
                                    </option>
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Category Of Company*</label>
                                <select className="form-select bg-white"
                                    aria-label="Default select example"
                                    value={categoryOfCompany}
                                    onChange={(e) => setCategoryOfCompany(e.target.value)}
                                    disabled={!isEditable}>
                                    <option>Category Of Company*</option>
                                    <option value="Individual">Individual</option>
                                    <option value="Contractor">Contractor</option>
                                    <option value="Sub-Contractor">Sub-Contractor</option>
                                    <option value="Vendor">Vendor</option>
                                    <option value="Sub-Vendor">Sub-Vendor</option>
                                    <option value="Employee">Employee</option>
                                </select>
                            </div>


                            <div className="col-md-6 mb-3">
                                <label className="form-label">Current password</label>
                                <input
                                    {...(isEditable ? { type: 'password' } : {})}
                                    type="password"
                                    className="form-control"
                                    id="current_Password"
                                    value={currentPassword}
                                    onChange={(e) => {
                                        setCurrentPassword(e.target.value);
                                    }}
                                    readOnly={!isEditable}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">New Password</label>
                                <input
                                    {...(isEditable ? { type: 'password' } : {})}
                                    type="password"
                                    className="form-control"
                                    id="new_Password"
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                    }}
                                    readOnly={!isEditable}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Confirm New Password</label>
                                <input
                                    {...(isEditable ? { type: 'text' } : {})}
                                    type="password"
                                    className="form-control"
                                    id="cnfNewPassword"
                                    value={cnfNewPassword}
                                    onChange={(e) => {
                                        setCnfNewPassword(e.target.value);
                                    }}
                                    readOnly={!isEditable}
                                />
                            </div>

                            {/* <div className="col-md-6 mb-3">
                                    <label className="form-label">Time Zone</label>
                                    <input
                                        {...(isEditable ? { type: 'text' } : {})}
                                        className="form-control"
                                        placeholder="Your Time Zone"
                                        //   disabled={!isEditable}
                                        readOnly={!isEditable}
                                    />
                                </div> */}
                        </div>

                    </div>
                </main>
            </div>


            {/* 
            <div className="container-fluid bg-light mb-5 mb-xl-10">
                <div className="card shadow-sm p-4">
                    <div className="d-flex flex-wrap align-items-center mb-10">
                        <div
                            id="kt_signin_password"
                            className={" " + (showPasswordForm && "d-none")}
                        >
                            <div className="fs-6 fw-bolder mb-1">Password</div>
                            <div className="fw-bold text-gray-600">************</div>
                        </div>

                        <div
                            id="kt_signin_password_edit"
                            className={"flex-row-fluid " + (!showPasswordForm && "d-none")}
                        >
                            <form
                                id="kt_signin_change_password"
                                className="form"
                                noValidate
                            >
                                <div className="row mb-1">
                                    <div className="col-lg-4">
                                        <div className="fv-row mb-0">
                                            <label
                                                htmlFor="currentpassword"
                                                className="form-label fs-6 fw-bolder mb-3"
                                            >
                                                Current Password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control form-control-lg form-control-solid "
                                                id="currentpassword"
                                            />

                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="fv-row mb-0">
                                            <label
                                                htmlFor="newpassword"
                                                className="form-label fs-6 fw-bolder mb-3"
                                            >
                                                New Password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control form-control-lg form-control-solid "
                                                id="newpassword"
                                            />

                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="fv-row mb-0">
                                            <label
                                                htmlFor="confirmpassword"
                                                className="form-label fs-6 fw-bolder mb-3"
                                            >
                                                Confirm New Password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control form-control-lg form-control-solid "
                                                id="confirmpassword"
                                            />

                                        </div>
                                    </div>
                                </div>

                                <div className="form-text mb-5">
                                    Password must be at least 8 character and contain symbols
                                </div>

                                <div className="d-flex">
                                    <button
                                        id="kt_password_submit"
                                        type="submit"
                                        className="btn btn-upwealth me-2 px-6"
                                    >

                                    </button>
                                    <button
                                        onClick={() => {
                                            setPasswordForm(false);
                                        }}
                                        id="kt_password_cancel"
                                        type="button"
                                        className="btn btn-color-gray-500 btn-active-light-upwealth px-6"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div
                            id="kt_signin_password_button"
                            className={"ms-auto " + (showPasswordForm && "d-none")}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    setPasswordForm(true);
                                }}
                                className="btn btn-light btn-active-light-upwealth"
                            >
                                Reset Password
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    );
};

export default UserDetails;
